@import 'design-system/styles';

.spinner,
%spinner {
  border: size(xxxs) solid rgba($white, 30%);
  border-top: size(xxxs) solid $white;
  border-radius: 50%;
  width: size(md);
  height: size(md);
  animation: spin 2s linear infinite;

  &-dark {
    @extend %spinner;

    border-color: rgba($purple-500, 30%);
    border-top-color: $purple-500;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
