@import 'design-system/styles';

.table {
  @include table-with-row-actions;

  td {
    padding: $spacing-md $spacing-xl;
    padding-left: $spacing-md;
  }

  thead tr th:first-of-type,
  tr td:first-of-type {
    p {
      width: min-content;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  thead tr th:not(:first-of-type),
  tr td:not(:first-of-type) {
    min-width: min-content;
    width: 20%;
  }

  thead tr th:nth-child(2),
  tr td:nth-child(2) {
    width: 100%;
  }
}

.has-upc {
  thead tr th:nth-child(2),
  tr td:nth-child(2) {
    width: 20%;
  }

  thead tr th:nth-child(3),
  tr td:nth-child(3) {
    width: 100%;
  }
}

.retailer-cell {
  display: flex;
  align-items: center;
  gap: size(xs);

  img {
    max-width: size(md);
  }
}

.policies {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: size(xs);
  align-items: center;
}

.dropdown-item {
  display: flex;
  align-items: center;
}
