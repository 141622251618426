.launch-dates-container {
  margin-bottom: 20px;

  .launch-date-container {
    display: flex;
    margin-left: 20px;

    label {
      margin: 8px 8px 0 0;
      width: 70px;
    }

    input {
      width: 200px;
    }
  }
}

.share-container {
  display: flex;
  gap: 8px;
  align-items: center;

  label {
    margin-bottom: 0;
  }
}
