@import 'design-system/styles';

$mobile-columns: var(--mobile-cols);
$tablet-columns: var(--tablet-cols);
$desktop-columns: var(--desktop-cols);

.grid-container {
  display: grid;
  grid-template-columns: repeat($mobile-columns, 1fr); // mobile-first
  // do not tie these to a size() util
  gap: 24px;
  row-gap: 0;
  margin: 0 var(--mobile-xmargin);

  @include breakpoint-up('tablet') {
    grid-template-columns: repeat($tablet-columns, 1fr);
    margin: 0 var(--tablet-xmargin);
  }

  @include breakpoint-up('desktop') {
    grid-template-columns: repeat($desktop-columns, 1fr);
    margin: 0 var(--desktop-xmargin);
    max-width: $max-width;
  }
}
