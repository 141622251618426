@import 'design-system/styles';

.container,
%container,
.tooltip,
.icon {
  display: flex;
  align-items: center;
}

.not-applicable {
  @extend %container;

  background-color: $neutral-200;

  img {
    filter: grayscale(1);
  }
}

.container,
%container {
  border: solid size(1) $neutral-200;
  padding: size(xxs);
  border-radius: size(100);
  gap: size(xs);
}

.divider {
  display: block;
  width: size(1);
  background-color: $neutral-200;
  height: size(12);
}

.tooltip {
  flex-direction: column;
  justify-content: center;
  gap: size(xxs);
}
