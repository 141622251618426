@import 'design-system/styles';

.root {
  box-shadow:
    0 1px 2px 0 #1018280f,
    0 1px 3px 0 #1018281a;
  border: 1px solid #eaecf0;
  background: #fff;
  border-radius: $spacing-xl;
  padding: $spacing-3xl;

  .content-row {
    display: flex;
    gap: $spacing-5xl;
    align-items: bottom;
    justify-content: space-between;
    margin-bottom: $spacing-xl;

    .text-and-badge {
      display: flex;
      gap: $spacing-md;
      align-items: center;
    }
  }

  .stats-row {
    display: flex;
    align-items: center;
    gap: $spacing-3xl;

    .stat {
      display: flex;
      gap: $spacing-md;
      align-items: center;
    }
  }
}
