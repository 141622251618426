@import 'design-system/styles';

.add-function-modal-input {
  @include text-style('text-md');

  color: $black;
  padding: size(12) size(sm);
  border-radius: size(12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: size(1) solid $neutral-200;
  width: 100%;
}
