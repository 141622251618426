@import 'design-system/styles';

.root {
  @include focus-state;

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: size(z);
  margin-top: size(xs);

  & > div:first-of-type {
    background-color: $white;

    &:hover {
      background-color: $neutral-200;
    }
  }

  .button-section {
    align-items: center;
    display: flex;
    gap: size(xs);
  }

  .pagination-buttons {
    display: flex;
    align-items: center;
    gap: size(xxs);
  }

  .button,
  %button {
    background: transparent;
    padding: size(1) size(6);
    border: size(1) solid transparent;
    color: $neutral-500;
    cursor: pointer;

    &-active {
      @extend %button;

      border: size(1) solid $black;
      border-radius: size(6);
    }

    &-nav {
      @extend %button;

      width: size(20);
      height: size(20);
      align-items: center;
      background: $purple-100;
      border-radius: 50%;
      color: $black;
      display: flex;
      justify-content: center;
    }
  }
}
