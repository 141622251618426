@import 'design-system/styles';

.root,
%root {
  width: fit-content;
  @include text-style('text-sm-semibold');
  @include focus-state;

  text-decoration: none;
}

.disabled {
  @extend %root;
  @include link-styles;

  color: $neutral-400;
  text-decoration: none;
  cursor: not-allowed;
  pointer-events: none;
}

.default {
  @include link-styles;
}

.new-default {
  @extend %root;
  @include new-link-styles;
}

.primary {
  @extend %root;
  @include button-primary;
}

.secondary {
  @extend %root;
  @include button-secondary;
}

.tertiary {
  @extend %root;
  @include button-tertiary;
}

.alternate {
  @extend %root;
  @include button-alternate;
}

.pill {
  @extend %root;
  @include button-pill;
}

.success {
  @extend %root;
  @include button-success;
}

.error {
  @extend %root;
  @include button-error;
}

.size-sm {
  @include button-small;
}

.size-md {
  @include button-medium;
}

.size-lg {
  @include button-large;
}

.size-xl {
  @include button-xl;
}

.size-2xl {
  @include button-2xl;
}
