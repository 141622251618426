@import 'design-system/styles';

.table {
  @include full-width-table(7);
  @include table-with-row-actions;
}

.table-row-retailer {
  display: flex;
  align-items: center;
  gap: size(xs);

  img {
    max-width: size(md);
  }
}

.table-row-dropdown-item {
  display: flex;
  align-items: center;
}
