@import 'design-system/styles';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: $purple-500;
  color: $white;

  a {
    color: $white;
    font-weight: bold;
  }

  &.info {
    background-color: $purple-800;
  }

  &.warning {
    background-color: $orange-200;
    color: $orange-800;

    a {
      color: $orange-800;
    }
  }

  &.offer {
    background-color: $blue-500;
  }

  &.offer-orange {
    background-color: $brand-orange;
    color: $black;

    a {
      color: $black;
    }
  }

  &.welcome {
    background-color: $brand-background;
    color: $brand-primary-800;

    a {
      color: $brand-primary-800;
    }
  }

  &.small {
    padding: size(20);
  }

  &.large {
    padding: size(48) 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
