@import 'design-system/styles';

$border-radius: size(12);

.menu-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-md;
  cursor: pointer;
}

.menu-content {
  & > li:first-of-type {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  & > li:last-of-type {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  & > li:not(:last-of-type) {
    border-bottom: none;
  }
}
