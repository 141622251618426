@import 'design-system/styles';

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;

  svg {
    position: absolute;
  }

  .large,
  %large {
    @include text-style('text-sm-bold');

    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    color: $black;
    text-align: center;
  }

  .small {
    @extend %large;

    font-size: 0.625rem;
    font-weight: 700;
  }

  .default {
    fill: $neutral-300;
  }

  .blue {
    fill: $blue-200;
  }

  .green {
    fill: $green-200;
  }

  .purple {
    fill: $purple-200;
  }

  .orange {
    fill: $orange-200;
  }
}
