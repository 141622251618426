@import 'design-system/styles';

.label {
  @include text-style('text-sm-medium');

  position: relative;
  display: flex;
  flex-direction: column;
  color: $neutral-700;
}

.small {
  .select {
    padding: $spacing-md;
  }

  .icon {
    bottom: 5.125px;
  }
}

// px perfect
.medium {
  .select {
    padding: 0.7345rem 0.875rem;
  }

  .icon {
    bottom: 7.125px;
  }
}

.select {
  appearance: none; // Removes native styling
  display: flex;
  margin-top: $spacing-md;
  align-items: center;
  gap: $spacing-8xl;
  align-self: stretch;
  border-radius: $spacing-md;
  border: 1px solid $neutral-300;
  background: $white;
  box-shadow: $shadow-xs;

  &:focus {
    border-radius: $spacing-md;
    border: 1px solid $purple-300;
    background: $white;
    outline-color: $purple-300;

    @include focus-state;
  }
}

.icon {
  position: absolute;
  right: 14px;
}
