@import './mixins';
@import './utils';

@include generate-color-classes;

:where(html) {
  color-scheme: light;
}

:root {
  /* Brand Color */
  // hsl representation of purple-600
  --brand-color-h: 249;
  --brand-color-s: 75%;
  --brand-color-l: 41%;
  --brand-color: #311ab6; // purlple-600

  /* Fonts */
  --font-sans-serif: circular, helvetica, sans-serif;

  font-family: var(--font-sans-serif);
}

body {
  background-color: $white;
}
