@import 'design-system/styles';

.root {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xl;

  .phone-prefix {
    max-width: 74px;
  }
}

.checkbox-container {
  display: flex;
  margin: $spacing-md $spacing-none;
  gap: $spacing-sm;
  align-items: baseline;
}

.stacked-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.row {
  display: flex;
  gap: $spacing-md;
  align-items: center;
}

.relative-parent {
  position: relative;
}

.italic {
  font-style: italic;
}

.pb-md {
  padding-bottom: $spacing-md;
}

.small-biz-box {
  background-color: $orange-50;
  border: 1px solid $orange-200;
  border-radius: $spacing-lg;
  padding: $spacing-3xl;
  margin-top: $spacing-3xl;
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;

  .small-biz-title {
    display: flex;
    gap: $spacing-md;
    align-items: center;
  }
}
