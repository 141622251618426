@import 'design-system/styles';

.sortable,
%sortable,
.static,
.tooltip {
  @include text-style('text-sm');

  white-space: nowrap;
}

.sortable,
%sortable {
  align-items: center;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: fit-content;

  .caret {
    margin-left: size(2);
    opacity: 0;
  }

  &:hover {
    cursor: pointer;

    .caret {
      opacity: 0.5;
    }
  }

  &:focus {
    outline-color: $blue-600;
    outline-offset: size(4);

    .caret {
      opacity: 1;
    }
  }

  &-active {
    @extend %sortable;

    .caret {
      opacity: 1;
    }
  }
}

.icon-desc {
  .caret svg {
    transform: scaleY(-1);
  }
}

.tooltip {
  display: flex;
  align-items: center;
  gap: size(xxs);

  & > div {
    margin-top: size(2);
  }
}
