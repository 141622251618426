.container {
  width: 70vw;
  height: 80vh;
  padding: 0;
}

.image {
  width: 70vw;
  height: auto;
}
