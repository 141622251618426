@import 'design-system/styles';

.search-filter {
  display: flex;
  flex-direction: row;
}

.drop-down-menus {
  display: flex;
  align-items: center;

  > div {
    margin-left: $spacing-md;
    margin-right: auto;
  }
}

.trigger-container {
  @include button-tertiary;

  box-shadow: none;
  border: none;
  color: $neutral-600;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  padding: $spacing-sm;

  &:focus-within {
    @include focus-styles;
  }
}
