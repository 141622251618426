@import 'design-system/styles';

.product-form {
  background: $white;
  display: flex;
  flex-direction: column;
}

.field {
  @include text-style('text-sm');

  display: grid;
  grid-template-columns: 1fr 28rem 1fr;
  padding: $spacing-lg $spacing-sm $spacing-lg $spacing-sm;
  border-bottom: 1px solid $gray-200;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    gap: $spacing-md;
  }
}

.buttons {
  display: flex;
  margin: 1rem;
  margin-left: auto;
}

.cancel-button {
  margin-right: 1rem;
}

.select {
  display: flex;
  flex-direction: column;
}
