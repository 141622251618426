@import 'design-system/styles';

.empty {
  border: size(1) solid $orange-500;
  border-radius: size(12);
  padding: size(sm);
  display: flex;
  align-items: center;
  gap: size(xs);
  background-color: $orange-100;
}
