@import 'design-system/styles';

.container {
  @include gray-rounded-border;

  background-color: $white;
  padding: size(sm);
}

.title {
  margin-bottom: size(sm);
}
