@import 'design-system/styles';

.empty-section {
  display: flex;
  align-items: center;
  gap: $spacing-xl;
  flex-direction: column;
  margin-bottom: $spacing-3xl;
}

.upload-container {
  margin-top: $spacing-3xl;
  display: flex;
  flex-direction: column;
  gap: $spacing-4xl;
  align-items: center;
  padding: 0 $spacing-5xl;
  margin-bottom: $spacing-8xl;

  .button-text {
    display: flex;
    align-items: center;
    gap: $spacing-xs;
  }
}

.retail-upload-wrapper {
  display: flex;
  justify-content: center;
  margin-top: $spacing-3xl;

  .retail-upload-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px solid $neutral-300;
    border-radius: $spacing-lg;
    padding: $spacing-xl $spacing-5xl;
    gap: $spacing-lg;
  }
}

.icon-box {
  border-radius: $spacing-md;
  border: 1px solid $neutral-200;
  padding: $spacing-md;
  display: flex;
}

.center-text {
  text-align: center;
}
