@import 'design-system/styles';

.root {
  margin: 0;
}

.caption {
  display: flex;
  align-items: center;
  gap: $spacing-lg;
  margin-bottom: $spacing-xl;

  img {
    height: $sizing-5xl;
  }
}

.table {
  // use dynamic table width
  @include dynamic-full-width-table;

  td {
    padding: $spacing-md $spacing-xl;
    padding-left: $spacing-md;
  }

  // col 1
  thead tr th:first-of-type,
  tr td:first-of-type {
    p {
      width: $spacing-9xl;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  // col 2
  @include breakpoint-up('tablet') {
    thead th:nth-child(2),
    tbody tr td:nth-child(2) {
      left: calc($sizing-7xl + $spacing-4xl);
      position: sticky;

      p {
        width: 200px;
        text-align: left;
      }
    }
  }

  [class*='-scrolled'] {
    thead th:nth-child(2),
    tbody tr td:nth-child(2) {
      box-shadow: size(xxxs) size(z) size(xxs) $neutral-100;
    }
  }
}

.cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;

  .cta {
    display: flex;
    align-items: center;

    .icon-container {
      padding-left: $spacing-md;
      display: flex;

      @include override-icon-color($purple-700);
    }
  }
}
