@import 'design-system/styles';

.root-container {
  display: block;
  margin-bottom: size(xs);
  border-bottom: 1px solid $neutral-200;
  border-top-right-radius: size(xs);
  border-top-left-radius: size(xs);
}

.root {
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;

  & > button:first-of-type {
    border-top-left-radius: size(xs);
  }

  & > button:last-of-type {
    border-top-right-radius: size(xs);
  }

  & > button {
    flex: 1 1 0;
    justify-content: center;
    min-width: 250px;
    width: 100%;
    max-width: max-content;
  }

  .slider {
    background-color: $purple-500;
    height: size(2);
    position: absolute;
    bottom: 0;
    transition: 0.2s all;
  }

  button {
    color: $neutral-500;
    white-space: nowrap;
  }
}

.additional-header {
  padding: $spacing-md;
}
