@import 'design-system/styles';

.active {
  button {
    box-shadow: inset 0 0 0 size(1) $orange-500;
  }

  .button {
    display: flex;
    align-items: center;
    gap: size(xs);
  }

  .icon {
    width: size(6);
    height: size(6);
    display: block;
    border-radius: 50%;
    background-color: $orange-500;
  }
}

.filter-row {
  display: flex;
  gap: size(xxs);
  align-items: center;
  flex-wrap: wrap;
}

.filter-button {
  box-shadow: none;
  border: none;
}
