@import 'design-system/styles';

.caption {
  display: flex;
  align-items: center;
  gap: $spacing-lg;
  margin-bottom: $spacing-xl;

  img {
    height: $sizing-5xl;
  }
}

.table {
  @include full-width-table(4);

  tr {
    &:hover {
      td {
        background-color: $neutral-50;
      }
    }
  }

  // first 2 columns
  td:nth-of-type(1),
  td:nth-of-type(2) {
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  tr.active td {
    background-color: $purple-50;
  }
}

.cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;

  .cta {
    display: flex;
    align-items: center;

    .icon-container {
      padding-left: $spacing-md;
      display: flex;

      @include override-icon-color($purple-700);
    }
  }
}

.brand-req-note {
  display: flex;
  flex-direction: column;
  padding: $spacing-xl $spacing-3xl $spacing-3xl $spacing-3xl;
  gap: $spacing-lg;
  border-radius: $spacing-lg;
  border: solid 1px $orange-200;
  background-color: $orange-25;

  > div {
    display: flex;
    gap: $spacing-md;
    align-items: center;
  }
}
