@import 'design-system/styles';

.md {
  margin-bottom: $spacing-md;
}

.xl {
  margin-bottom: $spacing-xl;
}

.three-xl {
  margin-bottom: $spacing-3xl;
}

.four-xl {
  margin-bottom: $spacing-4xl;
}

.full-height {
  height: 100%;
}

.panel {
  padding: size(xl);
  border-radius: size(sm);
  border: size(1) solid $neutral-200;
  box-shadow: $shadow-xs;
}

.fallback {
  background-color: $neutral-100;
  border-radius: size(sm);
}

.next-steps-wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-5xl;
}

.next-steps {
  display: flex;
  justify-content: space-between;

  .button {
    width: min-content;
  }

  img,
  .fallback {
    height: 10rem;
    margin-right: $spacing-5xl;
  }

  .fallback {
    width: 8.69rem;
  }

  .description {
    width: 70%;
    margin-bottom: $spacing-3xl;
  }

  .next-steps-left {
    display: flex;
    align-items: center;

    @include breakpoint-down('tablet') {
      flex-direction: column;
      gap: $spacing-3xl;

      img,
      .fallback {
        margin-right: 0;
      }
    }
  }

  .next-steps-center {
    display: flex;
    flex-direction: column;
    gap: $spacing-lg;
  }

  .next-steps-header {
    margin-bottom: $spacing-md;
  }

  .progress {
    display: flex;
    align-items: center;
    gap: $spacing-3xl;
  }

  // custom breakpoint
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: $spacing-5xl;

    .next-steps-header,
    .description {
      width: 100%;
    }

    .button {
      display: flex;
      align-self: flex-end;
    }

    .progress {
      justify-content: flex-end;
    }
  }
}

.retailer-policy-groups {
  padding: $spacing-5xl;

  .policy {
    padding-top: $spacing-3xl;
  }

  .button-link {
    text-decoration: none;
  }

  .group-container {
    margin-top: $spacing-3xl;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: $spacing-3xl;

    .group-content {
      display: flex;
      align-items: center;
      gap: $spacing-4xl;
    }

    .percent-review-block {
      display: flex;
      flex-direction: column;
      gap: $spacing-md;
    }

    .ring {
      flex-direction: row;
    }

    .bar {
      flex-direction: column;
    }

    .review,
    %review {
      display: flex;
      gap: $spacing-xl;
      justify-content: space-between;
    }

    // DESKTOP
    // 1/4 width (3+ items)
    & > * {
      @extend %review;

      grid-column: span 2;
    }

    // full width (one item total)
    & > *:only-child {
      @extend %review;

      grid-column: span 8;
    }

    // 1/2 width (two items total)
    & > *:nth-child(1):nth-last-child(2),
    & > *:nth-child(2):nth-last-child(1) {
      @extend %review;

      grid-column: span 4;
    }

    // TABLET
    @include breakpoint-down('desktop') {
      grid-template-columns: repeat(6, 1fr);

      & > *:only-child {
        grid-column: span 6;
      }

      & > *,
      & > *:nth-child(1):nth-last-child(2),
      & > *:nth-child(2):nth-last-child(1) {
        grid-column: span 3;
      }
    }

    // MOBILE
    @include breakpoint-down('tablet') {
      grid-template-columns: repeat(1, 1fr);

      & > * {
        grid-column: span 1;
      }
    }

    .requirement-group {
      padding: $spacing-3xl $spacing-4xl;
      border-radius: size(sm);
      border: size(1) solid $neutral-200;
      box-shadow: $shadow-xs;

      .title {
        display: flex;
        align-items: center;
        gap: $spacing-md;
        margin-bottom: $spacing-xl;
      }
    }
  }
}

.padded-heading {
  padding-top: $spacing-3xl;
}

.dash-footer {
  display: flex;
  gap: $spacing-3xl;
  padding-top: $spacing-xl;

  > * {
    flex: 1;
  }
}

.resources {
  display: flex;
  gap: size(xl);
  padding: size(xl);
  flex: 1;
  align-items: center;
}

.ring-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;

  .ring-one {
    position: absolute;
    top: $spacing-lg;
    left: $spacing-lg;
    padding: $spacing-2xl;
    display: flex;
    background: $orange-50;
    border-radius: 50%;
  }

  .ring-two {
    position: absolute;
    width: size(120);
    height: size(120);
    border-radius: 50%;
    border: size(2) solid $neutral-200;
    transform: translate(size(-48), size(-49));
  }

  .ring-three {
    position: absolute;
    width: size(180);
    height: size(180);
    border-radius: 50%;
    border: size(2) solid $neutral-100;
    transform: translate(size(-76), size(-77));
  }

  .ring-four {
    position: absolute;
    width: size(240);
    height: size(240);
    border-radius: 50%;
    border: size(2) solid $neutral-50;
    transform: translate(size(-108), size(-109));
  }

  .warning-icon-container {
    display: flex;
    padding: $spacing-md;
    background: $orange-100;
    width: min-content;
    border-radius: 50%;
    border: solid $orange-50 size(50)
      // trick with triangle icon to appear more centered
      svg {
      transform: translateY(size(-1));
    }
  }
}

.subtext {
  padding-bottom: $spacing-5xl;

  a {
    color: $purple-700;
  }
}

.contact-text {
  border-bottom: 1px solid $purple-700;
  color: $purple-700;
}

.modal-content {
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

.pricing {
  border-radius: $spacing-2xl;
  border: size(1) solid $neutral-200;
  height: 100%;
  padding: $spacing-4xl;
  display: flex;
  flex-direction: column;
  gap: $spacing-3xl;

  .title-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-xl;
  }

  .pricing-rows {
    display: flex;
    flex-direction: column;
    gap: $spacing-xl;
    padding: 0 $spacing-md;

    .pricing-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.steps-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-3xl;
  padding: $spacing-3xl;
}
