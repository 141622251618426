@import 'design-system/styles';

.ring-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;

  .ring-one {
    position: absolute;
    top: $spacing-lg;
    left: $spacing-lg;
    padding: $spacing-md;
    margin: $spacing-md;
    display: flex;
    background: $red-50;
    border-radius: 50%;
  }

  .ring-two {
    position: absolute;
    width: size(120);
    height: size(120);
    border-radius: 50%;
    border: size(1) solid $neutral-200;
    transform: translate(size(-48), size(-49));
  }

  .ring-three {
    position: absolute;
    width: size(180);
    height: size(180);
    border-radius: 50%;
    border: size(1) solid $neutral-100;
    transform: translate(size(-76), size(-77));
  }

  .ring-four {
    position: absolute;
    width: size(240);
    height: size(240);
    border-radius: 50%;
    border: size(1) solid $neutral-50;
    transform: translate(size(-108), size(-109));
  }

  .warning-icon-container {
    display: flex;
    padding: $spacing-md;
    background: $red-100;
    width: min-content;
    border-radius: 50%;
    border: solid $red-50 size(50)
      // trick with triangle icon to appear more centered
      svg {
      transform: translateY(size(-1));
    }
  }
}
