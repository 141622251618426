@import 'design-system/styles';

.pillars-container {
  h2 {
    margin-bottom: $spacing-4xl;
  }
}

.card {
  padding: $spacing-4xl;
  border-radius: $spacing-2xl;
  border: size(1) solid $neutral-200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-4xl;
  margin-bottom: $spacing-2xl;

  &:last-of-type {
    margin-bottom: $spacing-6xl;
  }

  @include breakpoint-down('tablet') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.progress-group {
  display: flex;
  align-items: center;
  gap: $spacing-xl;
}

.title-group {
  display: flex;
  align-items: center;
  gap: $spacing-lg;
  margin-bottom: $spacing-2xl;

  @include breakpoint-down('desktop') {
    gap: $spacing-xl;
  }
}

.card-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: $spacing-4xl;
  width: 100%;

  @include breakpoint-down('tablet') {
    flex-direction: column;
    align-items: flex-end;
  }
}

.message {
  padding: $spacing-8xl $spacing-3xl;
  background: $neutral-100;
  border-radius: $spacing-lg;
  text-align: center;

  img {
    max-width: size(64);
  }
}
