@import 'design-system/styles';

.container,
.section,
%section,
.row {
  display: flex;
}

.container,
.section,
%section {
  flex-direction: column;
}

.section,
%section,
.row {
  gap: size(xs);
}

.container {
  gap: size(md);

  p {
    margin: size(z);
  }
}

.row {
  align-items: center;
}

.alert,
.attestation {
  border-radius: size(12);
  border: size(1) solid;
}

.alert {
  @extend %section;

  padding: size(sm);
  border-color: $orange-500;
  background-color: $orange-100;
}

.attestation {
  padding: size(12);
  border-color: $neutral-200;
}
