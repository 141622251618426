@import 'design-system/styles';

.root {
  h1 {
    margin-bottom: $spacing-xl;
    color: $neutral-900;
  }

  color: $neutral-600;

  .content {
    margin-bottom: $spacing-3xl;
  }

  label {
    color: $neutral-700;
    font-weight: 700;
  }

  textarea {
    width: 100%;
    border: 1px solid $neutral-200;
    border-radius: size(8);
    padding: $spacing-lg;
    resize: none;
    @include text-style(text-md);
  }
}
