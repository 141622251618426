@import 'design-system/styles';

.container,
.title {
  display: flex;
  align-items: center;
}

.container {
  @include gray-rounded-border;

  justify-content: space-between;
  padding: size(sm);
  background-color: $white;
  border-radius: size(xs);
}

.title {
  gap: size(xxs);
}
