@import 'design-system/styles';

.table2 {
  @include full-width-table(2);

  table {
    display: table;
    width: 100%;
    overflow: hidden;

    thead tr th:nth-child(1) {
      width: 30%;
    }

    thead tr th:nth-child(2) {
      padding-left: $spacing-5xl;
    }

    tbody tr td:nth-child(2) {
      padding-left: $spacing-5xl;
    }
  }
}

.table3 {
  @include full-width-table(3);
  @include table-with-row-actions;
}

.ulta-dashboard-table {
  @include full-width-table(3);

  table {
    display: table;
    width: 100%;
    overflow: hidden;

    thead tr th:nth-child(1) {
      width: 30%;
    }

    thead tr th:nth-child(2) {
      padding-left: $spacing-5xl;
      width: 30%;
    }

    tbody tr td:nth-child(2) {
      padding-left: $spacing-5xl;
    }
  }
}

.avatar-cell {
  display: flex;
  gap: $spacing-md;
  align-items: center;
}

.button-wrapper {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
}

.documents-page-heading {
  margin-bottom: $spacing-3xl;
}

.documents-page-container {
  margin-bottom: $spacing-5xl;
}

.ulta-dashboard-heading {
  margin-bottom: $spacing-3xl;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ulta-dashboard-container {
  margin-bottom: $spacing-5xl;
}
