@import 'design-system/styles';

.container {
  padding-top: size(5);
  flex-grow: 1;

  & > * {
    margin-bottom: size(sm);
  }
}

.name {
  margin-bottom: size(lg);
}

.policy {
  margin-bottom: size(xs);
  display: flex;
  align-items: center;
  gap: size(sm);
  padding: size(12) size(sm);
  border: solid 1px $neutral-200;
  border-radius: size(xs);
}

.status {
  padding: size(sm) 0;
  display: flex;
  align-items: center;
}

.dot {
  width: size(6);
  height: size(6);
  border-radius: 50%;
  margin-right: size(sm);
}

.green {
  background-color: $green-500;
}

.orange {
  background-color: $orange-500;
}

.red {
  background-color: $red-500;
}

.policy-name {
  margin-right: auto;
}

.coming-soon-wrapper {
  margin-top: size(xl);
}

.blur-wrapper {
  position: relative;
  border-radius: size(sm);
  margin-top: size(sm);
  background: $neutral-200;
}

.blur {
  padding: size(md);
  filter: blur(10px);
}

.overlay-pill {
  position: absolute;
  top: 0;
  bottom: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
