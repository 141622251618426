@import 'design-system/styles';

.root {
  background-color: $white;
  border: size(1) solid $neutral-200;
  padding: size(xs) size(12);
  list-style: none;
  cursor: pointer;
  min-width: fit-content;

  &:hover:not(.disabled) {
    background-color: $neutral-200;
  }
}

.disabled > * {
  opacity: 0.55;
}
