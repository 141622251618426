@import 'design-system/styles';

.root {
  width: size(6);
  height: size(6);
  border-radius: 50%;
  background: $red-500;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $red-500;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 size(10) rgb(0 0 0 / 0%);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 0%);
  }
}
