@import 'design-system/styles';

.filters {
  display: flex;
  gap: $spacing-lg;
  align-items: center;
  margin-bottom: $spacing-lg;
}

.sort {
  color: $neutral-600;
  text-decoration: none;
  padding: $spacing-xs;

  &:hover {
    color: $neutral-700;
  }
}

.brand-card {
  padding: $spacing-3xl;
  border: 1px solid $neutral-100;
  margin-bottom: $spacing-3xl;
  border-radius: $spacing-xl;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: $spacing-xl;

  .title {
    display: flex;
    align-items: center;
    gap: $spacing-md;
  }

  button {
    flex-basis: size(100);
  }

  ul {
    list-style-type: none;
  }

  li {
    display: flex;
    align-items: center;
    gap: $spacing-lg;
    flex-wrap: wrap;
    margin-bottom: $spacing-xs;
  }

  .submitted-text {
    color: $neutral-500;
  }

  .cta-container {
    display: flex;
    gap: $spacing-xl;
  }
}
