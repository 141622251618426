@import 'design-system/styles';

.upload-row {
  display: flex;
  gap: $spacing-5xl;
  width: 100%;

  .icon-box {
    border: 1px solid $neutral-200;
    border-radius: $spacing-md;
    padding: 10px;
  }

  .download-link {
    text-decoration: none;
    color: inherit;
  }

  .upload-row-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $spacing-md;

    .upload-box {
      background-color: white;
      border: 1px solid $neutral-300;
      border-radius: $spacing-lg;
      padding: $spacing-xl $spacing-5xl;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing-lg;
      cursor: pointer;
      flex: 1;
    }

    .drag-active {
      background-color: $neutral-300;
      border: 1px solid $neutral-600;
    }
  }
}

.error-row {
  margin-top: $spacing-2xl;

  li:first-of-type {
    list-style: none;
    margin-left: 0;
  }

  li {
    margin-left: $spacing-xl;
  }
}

.fixed-button {
  position: sticky;
  display: block;
  bottom: 0;
  margin: auto;
  margin-top: $spacing-xl;
}
