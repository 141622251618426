@import 'design-system/styles';

.menu-item-content {
  display: flex;
  align-items: center;
  gap: $spacing-md;
  padding: $spacing-md;
}

.button {
  span {
    display: flex;
    align-items: center;
    gap: $spacing-md;
  }
}
