@import 'design-system/styles';

.container {
  & > *:not(:last-child) {
    margin-bottom: size(sm);
  }
}

.section,
%section {
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > * {
    max-width: 50%;
  }

  &-alternate {
    @extend %section;

    flex-direction: column;

    & > p:first-of-type {
      margin-bottom: size(xs);
    }
  }
}

.compositions {
  & > p:not(:last-of-type) {
    margin-bottom: size(xxs);
  }
}
