@import 'design-system/styles';

.primary {
  @include button-primary;
}

.secondary {
  @include button-secondary;
}

.tertiary {
  @include button-tertiary;
}

.alternate {
  @include button-alternate;
}

.pill {
  @include button-pill;
}

.success {
  @include button-success;
}

.error {
  @include button-error;
}

.link {
  @include button-link;
}

.anchor {
  @include button-anchor;
}

.close {
  @include button-close;
}

.size-sm {
  @include button-small;
}

.size-md {
  @include button-medium;
}

.size-lg {
  @include button-large;
}

.size-xl {
  @include button-xl;
}

.size-2xl {
  @include button-2xl;
}
