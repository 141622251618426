@import 'design-system/styles';

.container {
  min-height: 100%;
  background-color: $purple-25;
  padding-top: 12rem;

  .welcome {
    text-align: center;
  }

  a {
    color: $purple-700;
    text-decoration: none;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      color: $purple-800;

      @include override-icon-color($purple-700);
    }
  }

  .retailers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: size(20);
    width: 100%;

    .loading,
    .retailer {
      width: 100%;
      margin-bottom: size(sm);
    }

    .retailer {
      height: 6.5rem;
      display: flex;
      gap: $spacing-5xl;
      flex-direction: row;
      align-items: center;
      padding: 1.5rem;
      border-radius: 1rem;
      background-color: $white;
      justify-content: space-between;

      .retailer-logo {
        height: 100%;
        display: flex;
        align-items: center;

        img {
          width: auto;
          max-width: 100%;
          height: inherit;
          max-height: 100%;
        }
      }

      a {
        display: inline-block;
      }
    }
  }

  .icon-container {
    padding-left: $spacing-md;
    display: flex;

    @include override-icon-color($purple-700);
  }

  .workbench-link {
    margin-bottom: $spacing-8xl;
  }
}
