@import 'design-system/styles';

.text {
  margin-bottom: size(md);
}

.image {
  width: 70vw;
  height: auto;
}
