@import 'design-system/styles';

.content {
  display: flex;
  gap: $spacing-3xl;
}

.submission-info {
  display: flex;
  flex-direction: column;
  gap: $spacing-3xl;
  flex: 1;
}

.payment-info {
  display: flex;
  flex-direction: column;
  border-radius: $spacing-lg;
  box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
  padding: $spacing-4xl $spacing-xl;
  background-color: $neutral-100;
  max-width: 350px;
  gap: $spacing-xl;
  height: fit-content;
}

.payment-block {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
}

.skus-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-3xl 0;
  border-top: 1px solid $neutral-300;
  border-bottom: 1px solid $neutral-300;
}

.skus-count {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}

.total-block {
  display: flex;
  justify-content: space-between;
}

.actions-block {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
  padding: 0 $spacing-md;

  button {
    display: flex;
    justify-content: center;
  }
}

.sku-row {
  padding: $spacing-lg $spacing-3xl;
  border-bottom: 1px solid $neutral-200;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trash {
  cursor: pointer;
}

.footnote {
  font-style: italic;
}
