@import 'design-system/styles';

.container {
  @include gray-rounded-border;

  width: 100%;
  height: size(125);
  padding: size(md);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $white;

  .caption {
    margin: 0 0 size(xs);
  }

  .current {
    margin: 0;
  }

  .content {
    display: flex;
    align-items: center;
    margin-bottom: size(xs);

    .target {
      margin-right: size(xs);
    }
  }
}
