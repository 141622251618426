@import 'design-system/styles';

.table {
  @include full-width-table(2);

  table {
    display: table;
    width: 100%;
  }

  thead tr th:nth-child(1) {
    width: 10%;
  }
}

.confirmation-message {
  position: relative;
}

.search-container {
  padding: $spacing-lg 0;
}

.actions {
  display: flex;
  gap: $spacing-lg;
  padding-top: $spacing-3xl;

  button {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
