@import 'design-system/styles';

.container {
  .tabs > * {
    border-left: 1px solid $neutral-200;
    border-top: 1px solid $neutral-200;
    border-right: 1px solid $neutral-200;
    margin-bottom: 0;
    gap: 0;
  }

  .toggle {
    padding: $spacing-lg $spacing-3xl;
    border-left: 1px solid $neutral-200;
    border-right: 1px solid $neutral-200;
  }

  .table table {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
  }
}
