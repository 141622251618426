@import 'design-system/styles';

.actions {
  display: flex;
  gap: $spacing-lg;
  padding-top: $spacing-3xl;

  button {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

.confirmation-message {
  position: relative;
}
