@import 'design-system/styles';

$border-radius: size(12);

.container {
  width: fit-content;

  p {
    margin: size(z);
  }
}

.menu,
%menu {
  @include gray-rounded-border;

  display: none;
  position: absolute;
  width: fit-content;
  min-width: size(200);
  z-index: $z-index-tooltip;
  padding: size(md);
  margin: size(xs) size(z) size(z) size(z);
  background-color: $white;

  &-show {
    @extend %menu;

    display: block;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: size(sm);

    &-button {
      @include text-style('text-sm');

      color: $blue-700;
      background-color: transparent;
      border: none;
      padding: size(z);
      cursor: pointer;
    }
  }
}

.filters {
  padding: size(z);
  margin: size(z);

  .filter:not(:last-of-type) {
    margin-bottom: size(xs);
  }
}

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-name {
    display: flex;
    margin-right: size(sm);

    p:first-of-type {
      margin-right: size(xxs);
    }
  }
}
