@import 'design-system/styles';

.root,
%root {
  width: size(44);
  height: size(24);
  align-items: center;
  background: $neutral-300;
  border: none;
  border-radius: size(100);
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding: size(xxs);
  transition: all 0.2s;

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  &-active {
    @extend %root;

    background: $green-500;
    justify-content: flex-end;
  }

  .circle {
    width: size(sm);
    height: size(sm);
    background: $white;
    border-radius: 50%;
  }
}

.small {
  width: size(22);
  height: size(12);
  padding: size(xxxs);

  .circle {
    width: size(xs);
    height: size(xs);
  }
}
