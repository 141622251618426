@import 'design-system/styles';

.table {
  @include table-with-row-actions;

  overflow-x: auto;

  table {
    display: table;
    width: 100%;
  }

  thead tr th:nth-child(1) {
    width: 5%;
  }

  thead tr th:nth-child(2) {
    width: 40%;
  }

  thead tr th:nth-child(3) {
    width: 20%;
  }

  thead tr th:nth-child(4) {
    width: 15%;
  }

  thead tr th:nth-child(5) {
    width: 10%;
  }

  thead tr th:nth-child(6) {
    width: 20%;
  }
}

.actions-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: $spacing-xl;
}

.table-with-border {
  border: 1px solid $neutral-200;
  border-radius: $spacing-lg;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: $spacing-9xl;
}

.tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 20px;
}
