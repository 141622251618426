@import 'design-system/styles';

.grid-item {
  grid-column: span var(--mobile-span);

  &.offset {
    grid-column: var(--mobile-offset) / span var(--mobile-span);
  }

  @include breakpoint-up('tablet') {
    grid-column: span var(--tablet-span);

    &.offset {
      grid-column: var(--tablet-offset) / span var(--tablet-span);
    }
  }

  @include breakpoint-up('desktop') {
    grid-column: span var(--desktop-span);

    &.offset {
      grid-column: var(--desktop-offset) / span var(--desktop-span);
    }
  }
}
