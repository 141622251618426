@import 'design-system/styles';

.root {
  position: sticky;
  display: block;
  min-width: 100%;
  left: 0;

  tr {
    display: block;
  }

  td {
    display: block;
    padding: $spacing-lg $spacing-xl;
    background: $neutral-200;

    &:last-child {
      text-align: right;
    }
  }

  &.bg-white {
    td {
      background: white;
    }
  }
}
