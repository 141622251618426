@import 'design-system/styles';

.container {
  position: fixed;
  top: size(84);
  right: size(md);
  z-index: $z-index-toast;

  & > div {
    margin-bottom: size(xs);
  }
}
