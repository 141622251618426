@import 'design-system/styles';

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.show {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  animation: fade-in 0.35s;
  background-color: rgb(27 27 27 / 75%);
  z-index: $z-index-loading-overlay;
}

.hide {
  display: none;
}

.container {
  display: flex;
  align-items: center;
  gap: size(xs);
}

.spinner {
  border: size(xxxs) solid rgba($white, 30%);
  border-top: size(xxxs) solid $white;
  border-radius: 50%;
  width: size(sm);
  height: size(sm);
  animation: spin 2s linear infinite;
  margin-right: size(xs);
}
