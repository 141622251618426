@import 'design-system/styles';

.container {
  border: size(1) solid $neutral-200;
  border-radius: size(sm);
  padding: size(md);
  background-color: $white;

  &-inner {
    align-items: center;
    background-color: $neutral-100;
    border-radius: size(sm);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: size(xxl) size(md);

    & > *:last-child {
      margin-top: size(sm);
    }
  }
}
