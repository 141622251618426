@import 'design-system/styles';

.root,
%root {
  align-items: center;
  background: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  display: flex;
  gap: size(12);
  padding: size(sm);
  padding-bottom: size(sm);
  position: relative;

  &:hover {
    background: $neutral-200;
  }

  &.root-active {
    color: $purple-700;
  }
}
