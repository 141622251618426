@import 'design-system/styles';

.header {
  display: flex;
  align-items: center;
  gap: size(xs);
  margin-bottom: size(xs);
}

.subheader {
  margin-bottom: size(12);
}
