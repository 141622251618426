@import 'design-system/styles';

.container {
  label {
    @include text-style('text-sm-bold');
  }
}

.input-container,
%input-container {
  border: size(1) solid $neutral-200;
  border-radius: size(12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: size(xs);
  padding: size(12) size(sm);
  background-color: $white;
}

.input {
  @include text-style('text-md');
  @include focus-state;

  border: none;
  color: $neutral-800;
  min-width: size(225);
  width: 100%;
  padding: size(2);
  margin-right: size(sm);
}

.input-container-error,
.error {
  @extend %input-container;

  border-color: $orange-500;
}

.error {
  justify-content: flex-start;
  padding: size(21) size(sm);
  background-color: $orange-100;

  & > *:first-child {
    margin-right: size(xs);
  }
}
