@import 'design-system/styles';

.container {
  display: flex;
  justify-content: space-between;
  gap: size(sm);

  & > div {
    width: 100%;
  }
}
