@import 'design-system/styles';

.banner {
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(24 13 91 / 30%);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100vw;
  min-height: 100vh;
  margin: 0;

  .logo {
    position: absolute;
    top: 0;
    padding: $spacing-xl $spacing-3xl;
  }
}

.content {
  background: white;
  padding: $spacing-4xl 0;
  box-shadow: 0 20px 24px -4px rgb(16 24 40 / 8%);
  width: size(1080);
  max-width: calc(100vw - $spacing-11xl);
  text-align: center;
  margin: $spacing-9xl auto;

  button {
    width: 100%;
    justify-content: center;
  }

  h1 {
    margin: $spacing-6xl 0 $spacing-3xl;
  }

  h2 {
    text-align: left;
    margin-bottom: $spacing-3xl;
  }

  .sub-title {
    text-align: center;
  }

  .policy-box {
    padding: $spacing-3xl $spacing-4xl;
    margin-bottom: $spacing-3xl;
    border: size(1) solid $neutral-200;
    text-align: left;

    .title-group {
      display: flex;
      align-items: center;
      gap: $spacing-md;
    }

    @include border-radius(xl);
  }

  .description {
    margin-top: $spacing-md;
  }

  @include border-radius(xl);
}
