@import 'design-system/styles';

$border-radius: $spacing-lg;

.root-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-3xl;
  justify-content: space-between;
  height: 100%;
}

.root {
  position: relative;
  display: block;
  margin-bottom: $spacing-3xl;
}

.action-content-container {
  & > *:last-child {
    margin-bottom: $spacing-3xl;
  }
}

.center {
  width: 100%;
  height: size(270);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h3 {
    margin-top: 24px;
  }
}

.edit-container {
  background-color: $purple-25;
  padding: $spacing-xl;
  border-radius: size(12);
  margin-bottom: $spacing-3xl;

  .edit {
    margin-top: $spacing-lg;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.input,
%input {
  @include text-style('text-md');

  width: 100%;
  border: size(1) solid $neutral-200;
  padding: size(12);
  border-radius: size(12);
}

.requirement-container,
.attestation-container,
.warn-container {
  border-radius: size(12);
  padding: size(sm);

  ol.requirement-list {
    list-style-position: inside;

    li.requirement-list-item {
      @include text-style('text-md');
    }
  }
}

.requirement-container {
  margin-bottom: size(sm);
  padding-left: 0;
  padding-right: 0;

  .requirement-header {
    margin-bottom: size(xs);
  }
}

.attestation-container {
  display: flex;
  gap: size(sm);
  background: $neutral-100;
  margin-bottom: size(md);
}

.warn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: size(sm);
  border: size(1) solid $red-500;
  background: $red-100;
  margin-top: size(sm);
}

.section-header-text {
  margin: size(sm) 0 size(xs);

  &:first-child {
    margin-top: 0;
  }
}

.attestation-group {
  margin: size(18) 0;
}

.content-overflow {
  max-height: size(375);
}

.function-header {
  margin-bottom: size(sm);
}

.header-text {
  margin-bottom: size(sm);
}

.attestation-policies {
  list-style: none;
  padding: size(z);
  margin: size(z);

  li {
    display: flex;
    align-items: center;
    gap: size(xs);
    margin-bottom: size(xs);
  }
}

.modal-content {
  min-height: size(275);
}

.notes-section {
  margin-top: $spacing-xl;

  .textarea {
    @include text-style('text-md');

    resize: none;
    width: 100%;
    padding: $spacing-lg $spacing-xl;
    border: size(1) solid $neutral-200;
    border-radius: $border-radius;

    &--error {
      border: size(1) solid $red-500;
    }
  }

  textarea::placeholder {
    @include text-style('text-md');
  }

  .error-text {
    color: $red-500;
  }
}

.note-description {
  border: size(1) solid $neutral-200;
  border-radius: $border-radius;
  background-color: $neutral-100;
  padding: $spacing-xl;
}

.retailer-response {
  border: size(1) solid $purple-100;
  border-radius: $border-radius;
  padding: $spacing-lg;

  .section-header-text {
    border-radius: $border-radius;
    color: $purple-700;
    display: flex;
    gap: $spacing-lg;
  }

  .textarea {
    border: size(1) solid $purple-500;
    background-color: $purple-25;
    color: $purple-900;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-3xl;
    margin-top: $spacing-xs;
  }
}

.dialog-note {
  margin-top: $spacing-3xl;
  padding: $spacing-lg;
  border: size(1) solid $neutral-200;
  border-radius: $border-radius;
  background-color: $neutral-100;
  display: flex;
  align-items: center;
  gap: 10px;
}

.alternatives-wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
  padding-top: $spacing-3xl;
}

.attest-btn-container {
  margin-top: $spacing-5xl;

  .attest-btn {
    margin-top: $spacing-md;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.toast {
  display: flex;
  gap: 1rem;
  min-width: 25rem;
  align-items: flex-start;
  justify-content: space-between;
}

.input-grid {
  display: grid;
  gap: size(sm);
  grid-template-columns: 1fr 1fr;
  max-height: size(400);
  overflow-y: auto;
}

.callout-card {
  padding: $spacing-xl $spacing-3xl $spacing-3xl $spacing-3xl;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;

  &-pass {
    background-color: $green-25;
    border: 1px solid $green-200;

    svg {
      fill: $green-600;
    }
  }

  &-fail {
    background-color: $red-25;
    border: 1px solid $red-200;

    svg {
      fill: $red-600;
    }
  }

  &-header {
    display: flex;
    gap: $spacing-md;
    align-items: center;
    font-weight: 600;
  }
}

.spacing-top-xl {
  margin-top: $spacing-xl;
}

.flex-column-gap-spacing-xl {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
  color: $neutral-700;

  *::first-letter {
    text-transform: uppercase;
  }
}

.icon-container,
.icon-inner {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container {
  width: $spacing-6xl;
  min-width: $spacing-6xl;
  height: $spacing-6xl;

  &-green {
    background-color: $green-50;
  }

  &-red {
    background-color: $red-50;
  }

  .icon-inner {
    width: $spacing-4xl;
    height: $spacing-4xl;

    &-green {
      background-color: $green-100;

      svg {
        fill: $green-700;
      }
    }

    &-red {
      background-color: $red-100;

      svg {
        fill: $red-700;
      }
    }
  }
}

.footer {
  border-top: solid 1px $neutral-300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-xl $spacing-3xl;
  position: sticky;
  bottom: 0;
  background-color: $white;

  @media screen and (max-height: 400px) {
    position: relative;
  }
}

.modal-nav {
  display: flex;
  gap: $spacing-md;

  // TODO: update button size variants to support this out of the box.
  button {
    padding: $spacing-md;
    box-shadow: inset 0 0 0 1px $neutral-300;
    border: 1px solid transparent;
  }
}
