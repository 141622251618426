@import 'design-system/styles';

.container {
  position: relative;
  min-width: size(12);
  min-height: size(12);

  svg {
    cursor: pointer;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.root,
%root {
  min-width: $sizing-xl;
  min-height: $sizing-xl;
  width: $sizing-xl;
  height: $sizing-xl;
  border-radius: $sizing-xs;
  appearance: none;
  background: $white;
  border: solid 1px $neutral-300;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0;
  position: relative;
  transition: all 0.3s ease;

  svg {
    cursor: pointer;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-lg {
    @extend %root;

    min-width: size(md);
    min-height: size(md);
    width: size(md);
    height: size(md);
    border-radius: size(xxs);
  }

  &:hover {
    background-color: $purple-25;
  }

  &:focus {
    box-shadow: 0 0 0 $sizing-xs #98a2b324;
  }
}

.active {
  background-color: $purple-700;
  border: solid 1px $purple-700;

  &:focus {
    box-shadow: 0 0 0 $sizing-xs #9e77ed3d;
  }

  &:hover {
    background-color: $purple-900;
  }
}

.disabled {
  background: $neutral-100;
  border: size(1) solid $neutral-200;
  cursor: not-allowed;
}
