@import 'design-system/styles';

.progress-container {
  display: flex;
  justify-content: space-evenly;
}

.progress-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-lg;
}

.form-content {
  padding: $spacing-2xl 0;
}

.checkbox-container {
  display: flex;
  margin: $spacing-md $spacing-none;
  gap: $spacing-sm;
  align-items: baseline;
}

.italic {
  font-style: italic;
}
