@import 'design-system/styles';

.user-list {
  position: relative;
  display: flex;
  margin-left: size(9.12);
  cursor: pointer;
}

.avatar-wrapper {
  position: relative;
  display: block;
  border: 1px solid $neutral-200;
  border-radius: 50%;
  margin-left: -(size(9.12));
  z-index: var(--z-index);
  transition: transform 0.2s ease-in-out;

  &:hover,
  &:focus {
    z-index: calc(100 + var(--z-index));
    transform: translateY(-(size(3)));
  }

  &:focus {
    box-shadow: 0 size(2) size(4) rgb(0 0 0 / 5%);
  }
}

.modal-user-list {
  overflow-y: scroll;
  max-height: 60vh;
}

.modal-user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: size(xs) 0;
  border-bottom: 1px solid $neutral-200;
}
