@import 'design-system/styles';

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.input-wrapper {
  margin-bottom: $spacing-2xl;
}
