@import 'design-system/styles';

.root {
  border-radius: size(12);
  padding: size(lg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  background: $blue-100;
  border: size(1) dashed $blue-500;
  cursor: pointer;
}

.disabled {
  background: $neutral-100;
  border: size(1) dashed $neutral-500;
  cursor: not-allowed;
  color: $neutral-500;
}

.file-list {
  padding: 0;
  margin: 0;
}

.file-container {
  border: size(1) solid $neutral-300;
  border-radius: size(12);
  padding: size(12);
  margin-top: size(xs);
  display: flex;
  align-items: center;
  justify-content: space-between;

  button:first-of-type {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  span {
    display: flex;
    align-items: center;
    gap: size(xs);
  }
}

.click-to-upload-text {
  text-decoration: underline;
  margin-right: size(xxs);
}

.upload-box {
  background-color: white;
  border: 1px solid $neutral-300;
  border-radius: $spacing-lg;
  padding: $spacing-xl $spacing-5xl;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-lg;
  cursor: pointer;
  flex: 1;
}

.drag-active {
  background-color: $neutral-300;
  border: 1px solid $neutral-600;
}

.icon-box {
  border: 1px solid $neutral-200;
  border-radius: $spacing-md;
  padding: 10px;
}
