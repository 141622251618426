@import 'design-system/styles';

.processing,
.supplier-contacted,
.preparing-for-shipment,
.shipped-to-downpacking,
.downpacking,
.shipped,
.out-for-delivery,
.test {
  & > div {
    background-color: $blue-100;
    color: $blue-800;
  }
}

.delivered > div {
  background-color: $green-100;
  color: $green-800;
}
