@import 'design-system/styles';

.fixed {
  @include breakpoint-down('tablet') {
    position: fixed;
    left: 0;
    right: 0;
  }
}

.hidden-mobile {
  @include breakpoint-down('tablet') {
    display: none;
  }
}

.content-container {
  margin: $spacing-4xl 0;
}
