@import 'design-system/styles';

@keyframes slide-in {
  0% {
    top: 35vh;
  }

  100% {
    top: 15vh;
  }
}

@keyframes slide-over {
  0% {
    right: -100%;
  }

  100% {
    right: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.show {
  animation: fade-in 0.35s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  left: 0;
  z-index: $z-index-modal;
}

.hide {
  display: none;
}

.backdrop {
  width: 100%;
  height: 100%;
  background-color: rgb(27 27 27 / 75%);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-modal;
}

.backdrop-disable {
  cursor: default;
}

.container,
%container {
  @include border-radius('2xl');

  animation: slide-in 0.25s;
  background-color: $white;
  border: size(1) solid $neutral-200;
  box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
  cursor: default;
  max-height: 75vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: size(475);
  z-index: $z-index-modal;
  top: 15vh;
  overflow: auto;

  @media (min-height: 400px) {
    min-height: size(300);
  }

  &-small {
    @extend %container;
  }

  &-medium {
    @extend %container;

    width: size(500);
  }

  &-large {
    @extend %container;

    width: size(650);
  }

  &-extra-large {
    @extend %container;

    width: 90vw;
    max-width: size(1500);
  }

  &-slide-out {
    @extend %container;

    animation: slide-over 0.35s;
    top: 0;
    bottom: 0;
    right: 0;
    width: 400px;
    max-width: 90vw;
    border-radius: 0;
    max-height: 100vh;
  }
}

.childless-container {
  @extend %container;

  min-height: 0;
}

.container-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: inherit;
  flex-grow: 1;
  overflow-y: hidden;

  @media (max-height: 400px) {
    overflow-y: auto;
  }
}

.header,
.content {
  padding: $spacing-3xl;
  padding-top: $spacing-xl;
}

.content-shrink-padding {
  padding-bottom: $spacing-3xl !important;
}

.subtitle {
  padding: 0 $spacing-3xl;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: $white;

  @media (max-height: 400px) {
    position: relative;
  }

  & > *:first-child {
    margin: size(0);
  }
}

.content {
  padding-bottom: size(52);
  flex-grow: 1;
  overflow-y: auto;

  @media (max-height: 400px) {
    overflow-y: visible;
  }
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background-color: $white;

  @media (max-height: 400px) {
    position: relative;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: $spacing-xl $spacing-3xl $spacing-3xl $spacing-3xl;

  & > *:first-child {
    margin-right: $spacing-xl;
  }
}

.confirm-close-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  left: 0;
  z-index: $z-index-toast;
}

.confirm-close-backdrop {
  width: 100%;
  height: 100%;
  background-color: rgb(27 27 27 / 20%);
  backdrop-filter: blur($spacing-md);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: $z-index-modal;
}

.confirm-close-content {
  @include border-radius('2xl');

  background-color: $white;
  border: size(1) solid $neutral-200;
  box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
  cursor: default;
  max-height: 75vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: size(400);
  z-index: $z-index-toast;
  top: 15vh;
  overflow: auto;

  @media (min-height: 400px) {
    min-height: size(146);
  }

  .footer {
    padding-top: 0;
    display: flex;
    justify-content: space-between;

    button {
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }
}

.icon-container,
.icon-inner {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container {
  width: $spacing-6xl;
  height: $spacing-6xl;
  background-color: $orange-50;

  .icon-inner {
    width: $spacing-4xl;
    height: $spacing-4xl;
    background-color: $orange-100;
  }

  svg {
    fill: $orange-700;
  }
}

.confirm-close-content-inner {
  padding: 0 $spacing-3xl $spacing-4xl $spacing-3xl;
  flex-grow: 1;

  .description {
    margin-top: $spacing-lg;
  }
}
