@import 'design-system/styles';

.container {
  display: flex;
  padding: size(xxl) size(md);

  & > *:nth-child(2) {
    margin-left: size(md);
    flex-grow: 1;
    height: fit-content;
  }
}
