@import 'design-system/styles';

.root {
  display: flex;
  flex-direction: column;
  gap: $spacing-3xl;
}

.close {
  cursor: pointer;
}

.close-wrapper {
  display: flex;
  justify-content: flex-end;
}

.pill-and-content-wrapper {
  display: flex;
  align-items: center;
  gap: $spacing-md;
}

.option {
  border: 1px solid $neutral-200;
  border-radius: $spacing-md;
  display: flex;
  align-items: center;
  padding: $spacing-3xl;
  justify-content: space-between;
  transition: 0.2s;
}

.not-selectable {
  background-color: $neutral-50;
  color: $neutral-500;
}

.option-selectable:hover {
  box-shadow: 0 1px 2px 4px #9e77ed3d;
  cursor: pointer;
}

.option-selected {
  box-shadow: 0 1px 2px 4px #9e77ed3d;
}

.icon {
  color: transparent;

  &[data-active='true'] {
    color: $purple-700;
  }

  &[data-current='true'] {
    color: $neutral-500;
  }
}

.icon-spacer {
  width: $spacing-4xl;
  height: $spacing-4xl;
}

.subtext {
  color: $neutral-500;
}

.subtext2 {
  color: $neutral-600;
}

.price-wrapper {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
  flex: 1;
  justify-content: flex-end;
  padding-left: $spacing-5xl;
}

.button {
  display: flex;
  justify-content: center;
}

.title-wrapper {
  display: flex;
  align-items: center;
  gap: $spacing-3xl;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.fine-print {
  color: $neutral-500;
  font-style: italic;
  text-align: center;
  margin-top: $spacing-xl;
}
