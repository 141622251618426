@import 'design-system/styles';

.root,
%root {
  display: grid;
  grid-template-columns: size(264) 1fr;
  margin: size(xxl) size(200) 0 size(24);
  gap: size(md);
  padding: 0;
  align-items: flex-start;
}
