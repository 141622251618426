@import 'design-system/styles';

.autocomplete {
  position: relative;
  width: 100%;
  margin: auto;
}

.dropdown {
  position: absolute;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  z-index: 10000;
  overflow-x: clip;
}

.dropdown-hidden {
  visibility: hidden;
  height: 0;
  border: 0;
}

.dropdown-visible {
  visibility: visible;
}

.option {
  padding: $spacing-md $spacing-xl;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.no-options {
  padding: $spacing-md $spacing-xl;
  color: gray;
}

.option:hover {
  background-color: lightgray;
}

.highlighted {
  background-color: lightgray;
}

.pill {
  background-color: $purple-50;
  display: flex;
  border-radius: $spacing-md;
  padding: 0 $spacing-md;
  align-items: center;
}

.pill-icon-wrapper {
  display: flex;
  cursor: pointer;
  padding: 0 $spacing-xxs;
}
