@import 'design-system/styles';

.root,
%root {
  @include focus-state;

  align-items: center;
  border: none;
  border-radius: size(100);
  cursor: pointer;
  display: flex;
  gap: size(4);
  justify-content: center;
  position: relative;
  transition: all 0.25s;

  &-pill {
    @extend %root;

    background-color: $neutral-100;
    max-height: size(24);
    padding: $spacing-md;
  }

  &-text {
    @extend %root;

    justify-content: space-between;
    border: solid $neutral-300 size(1);
    border-radius: $sizing-md;
    padding: $spacing-md;
    padding-right: 0.88rem;

    input {
      width: 200px;
      height: size(24.16);
      text-overflow: ellipsis;
      min-width: 70px;
    }
  }

  .options-container {
    background-color: white;
    border: size(1) solid $neutral-300;
    border-radius: $sizing-md;
    box-shadow: $shadow-xs;
    left: 0;
    padding: size(4);
    position: absolute;
    top: calc(100% + size(4));
    z-index: 4;
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
    min-width: max-content;
  }
}

.disabled {
  background-color: $neutral-100;
  color: $neutral-400;
  cursor: not-allowed;
  @include override-icon-color($neutral-400);
}

.placeholder {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  input::placeholder {
    @include text-style('text-sm');

    color: $neutral-500;
  }
}

.search {
  @include text-style('text-sm');

  border: none;
  background-color: transparent;
  margin: 0 spacing(xs);

  &:focus {
    outline: none;
    border: none;
  }
}

.error {
  border: size(1) solid $orange-500;
  border-radius: $sizing-md;
}

.error-message {
  margin-top: size(xs);
  padding: size(21) size(sm);
  display: flex;
  background-color: $orange-100;

  & > *:first-child {
    margin-right: size(xs);
  }
}

.compact {
  padding: spacing(md) spacing(lg);

  span {
    @include text-style('text-sm-medium');
  }
}
