@import 'design-system/styles';

.root,
%root {
  border-radius: size(12);
  background-color: $neutral-200;
  padding: size(md);

  svg {
    max-width: size(lg);
  }

  &-blue {
    @extend %root;

    background-color: $blue-600;
  }
}
