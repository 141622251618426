@import 'design-system/styles';

.filter-dropdown-container {
  &:focus-within {
    .trigger-button {
      @include focus-styles;
    }
  }
}

.dropdown-content {
  box-sizing: border-box;
  width: 240px;
  background-color: white;
  padding: $spacing-md $spacing-sm;
  border: 1px solid $gray-200;
  border-radius: $spacing-md;
  box-shadow: $shadow-lg;
  z-index: $z-index-tooltip;
}

.trigger-button {
  @include button-tertiary;

  box-shadow: none;
  border: none;
  gap: $spacing-md;
  display: flex;
  align-items: center;
}

.checkbox-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-md $spacing-md;

  &:focus {
    outline-color: $purple-300;
  }

  label {
    @include text-style('text-sm-medium');

    color: $gray-700;
    cursor: pointer;
  }

  svg {
    flex-shrink: 0;
  }

  &.selected {
    background-color: $gray-50;
  }
}

.accordion-content .checkbox-item {
  padding-left: $spacing-xl;
}
