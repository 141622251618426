@import 'design-system/styles';

.table {
  @include full-width-table(4);

  tr {
    &:hover {
      td {
        background-color: $neutral-50;
      }
    }
  }

  tr.active td {
    background-color: $purple-50;
  }
}

.trigger-container {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  padding: $spacing-md;
}

.cta-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .cta {
    display: flex;

    .icon-container {
      padding-left: $spacing-md;
      display: flex;

      @include override-icon-color($purple-700);
    }
  }
}

.toggle-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
