@import 'design-system/styles';

.trigger {
  @include text-style('text-sm');

  display: flex;
  padding: 0.625rem 0.875rem;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: $spacing-md;
  border: 1px solid $neutral-300;
  background: $white;
  box-shadow: $shadow-xs;

  > span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-state='open'],
  &:focus {
    border-radius: $spacing-md;
    border: 1px solid $purple-300;
    background: $white;
    outline-color: $purple-300;

    /* Focus rings/ring-brand-shadow-xs */
    box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%), 0 0 0 4px rgb(158 119 237 / 24%);
  }

  &[data-placeholder] {
    color: $neutral-500;
  }
}

.content {
  min-width: var(--radix-select-trigger-width);
  max-height: var(--radix-select-content-available-height);
  background: $white;
  margin-top: 5px;
  border-radius: $spacing-md;
  border: 1px solid $neutral-200;
  box-shadow: $shadow-lg;
}

.item,
.label {
  display: flex;
  padding: 0.625rem 0.625rem 0.625rem $spacing-md;
  margin: 0 $spacing-sm;
  flex-direction: row;
  align-items: flex-start;
  gap: $spacing-8xl;
  flex: 1 0 0;
}

.item[data-disabled],
.label[data-disabled] {
  color: $neutral-400;
}

.item {
  border-radius: $spacing-sm;

  &:focus {
    background: $neutral-50;
    outline-color: $purple-300;
  }

  &:hover {
    background: $neutral-50;
    outline: transparent;
  }
}

.carrot-down {
  margin-left: auto;
  display: flex;
  align-items: center;
}
