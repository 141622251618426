@import 'design-system/styles';

.list {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(xs);
  list-style: none;
  margin: 0;
  padding: 0;

  li:not(:last-child) {
    display: flex;
    align-items: center;
    gap: spacing(xs);
  }

  .link {
    text-decoration: none;
  }

  .ellipsis {
    @include text-style('text-sm');

    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
