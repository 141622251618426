@import 'design-system/styles';

.policy {
  display: flex;
  align-items: center;
  gap: size(xs);
}

.button {
  float: right;
}
