@import 'design-system/styles';

.root {
  border: size(1) solid $neutral-200;
  border-radius: size(xs);
  list-style: none;
  padding: 0;
  margin: 0;

  & > *:first-child {
    border-radius: size(7) size(7) 0 0;
  }

  & > *:last-child {
    border-radius: 0 0 size(7) size(7);
  }

  &.no-border {
    border: none;
  }
}
