@import 'design-system/styles';

.active-text,
%active-text {
  @include text-style('text-md-semibold');

  color: $neutral-800;
}

.root,
%root {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: $spacing-sm;
  transition: all 0.2s;

  .accordion-title {
    color: $neutral-500;
    margin: 0;
  }

  &-open-title {
    @extend %root;

    .accordion-title {
      @extend %active-text;
    }
  }

  .title-bar {
    @include focus-state;
    @include border-radius(sm);

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-xl $spacing-2xl;
    padding-left: $spacing-lg;
    width: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  span {
    display: flex;
    align-items: center;
    gap: size(12);
  }

  ul {
    list-style-type: none;
    list-style-position: outside;
    margin: 0;
    padding: 0;
  }

  .open {
    opacity: 1;
    visibility: visible;
    height: auto;
  }

  .close {
    // ensure no space is taken up in DOM while retaining html structure
    // (good for SEO and a11y)
    visibility: hidden;

    // ensure icons are gone
    opacity: 0;
    height: 0;
  }

  .child > a,
  .child > button {
    padding-left: $spacing-6xl;
    margin-right: 0;
  }
}

.active {
  @extend %root;

  .title-bar {
    background-color: $neutral-100;
  }

  .accordion-title {
    @extend %active-text;
  }
}

.detail {
  padding: $spacing-xs $spacing-2xl;
}

// Alternate styling

.alternate,
%alternate {
  @extend %root;

  border-color: $neutral-200;
  border-top-right-radius: size(10);
  border-top-left-radius: size(10);
  background-color: $white;

  .title-bar,
  .detail {
    padding: size(md);
  }

  .accordion-title {
    color: $black;
    text-align: left;
  }

  .icon {
    padding: size(xs);
    border-radius: 50%;
  }

  &:hover {
    .title-bar span:first-of-type {
      & > svg > path {
        stroke: $neutral-800;
      }

      & > svg > rect {
        fill: $neutral-100;
      }
    }

    background-color: $white;

    .accordion-title {
      color: $neutral-800;
    }

    .icon {
      background-color: $neutral-100;
    }

    ul svg > path {
      stroke: initial;
    }
  }

  &:focus {
    border-top-right-radius: size(10);
    border-top-left-radius: size(10);
  }

  &-open-title,
  .active {
    @extend %alternate;

    border: size(1) solid $neutral-200;

    .title-bar,
    .detail {
      padding: size(md);
    }

    .title-bar {
      border-bottom: size(1) solid $neutral-200;
    }

    .accordion-title {
      color: $black;
    }

    .detail {
      border-top: none;
      background-color: $white;
    }
  }
}
