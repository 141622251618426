@import 'design-system/styles';

.container,
.panel {
  display: flex;
}

.container {
  flex-direction: column;
  margin-bottom: size(xxs);

  & > h1,
  & > h2,
  & > h3 {
    margin: size(z);
    margin-bottom: size(sm);
  }
}

.panel {
  gap: size(sm);
  justify-content: space-between;
  align-items: center;
}

.filters-and-actions {
  display: flex;
  gap: size(xs);
}
