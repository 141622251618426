@import 'design-system/styles';

.root {
  background-color: $white;
  border-radius: size(4);
  cursor: pointer;
  padding: size(6);
  z-index: 5;
  margin-bottom: 1px;

  &:hover {
    background-color: $neutral-200;
    transition: all 0.25s;
    box-shadow: 0 0 0 1px $purple-300;
  }
}

.selected {
  background-color: $neutral-200;
}
