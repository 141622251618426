@import 'design-system/styles';

.pillar-dashboard-card {
  padding: $spacing-4xl;
  border-radius: $spacing-xl;
  border: 1px solid $neutral-200;
  background: $white;
  box-shadow: $shadow-sm;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 950px) {
    flex-direction: column;
  }

  .pillar-contract-icon {
    width: $sizing-7xl;
    height: $sizing-7xl;
    border-radius: 50%;
    border: solid 1px $neutral-200;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .policy-contract-icon {
    display: flex;
    align-items: center;
  }

  .pillar-dashboard-content {
    display: flex;
    align-items: flex-start;
    gap: $spacing-5xl;
    justify-content: space-between;
    align-self: stretch;

    .pillar-dashboard-asset {
      align-self: center;
    }

    .pillar-dashboard-text {
      flex-grow: 1;
    }

    .pillar-dashboard-header {
      display: flex;
      align-items: center;
      align-self: stretch;
      gap: $spacing-md;
      margin-bottom: $spacing-lg;

      @include breakpoint-down('tablet') {
        flex-direction: column;
        gap: $spacing-md;
        align-items: start;
      }
    }
  }

  .pillar-dashboard-cta {
    display: flex;
    height: 36px;
    justify-content: center;
    align-items: right;
    border-radius: $spacing-md;
    gap: $spacing-lg;

    button {
      height: 36px;
    }

    .cta {
      display: flex;
      align-items: center;

      .icon-container {
        padding-left: $spacing-md;
        display: flex;

        @include override-icon-color($purple-700);
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing-2xl;
  gap: $spacing-4xl;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
