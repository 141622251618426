@import 'design-system/styles';

.table {
  @include full-width-table(4);
}

.cta {
  display: flex;

  .icon-container {
    padding-left: $spacing-md;
    display: flex;

    @include override-icon-color($purple-700);
  }
}

.trigger-container {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  padding: $spacing-md;
  text-wrap: nowrap;
}

.options-row {
  display: flex;
  align-items: center;
  gap: $spacing-5xl;
  margin-bottom: $spacing-3xl;
}

.filters-wrapper {
  display: flex;
  gap: $spacing-3xl;
}
