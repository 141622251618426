@import 'design-system/styles';

.container {
  @include gray-rounded-border;

  background-color: $white;
  width: fit-content;
  min-width: size(176);
  padding: size(md);

  p {
    margin: size(z);
  }
}

.title {
  margin: size(z) size(z) size(sm) size(z);
}

.data {
  display: flex;
  align-items: center;
}

.difference {
  margin-left: size(xs);
}
