@import 'design-system/styles';

.info-content {
  display: flex;
  flex-direction: column;
  padding: $spacing-xl $spacing-3xl;
  gap: $spacing-2xl;
}

.content-row {
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;
}

.info-container {
  border-radius: $spacing-lg;
  border: 1px solid $neutral-200;
  box-shadow:
    0 1px 2px 0 rgb(16 24 40 / 6%),
    0 1px 3px 0 rgb(16 24 40 / 10%);
}

.info-header {
  display: flex;
  justify-content: space-between;
  padding: $spacing-xl $spacing-3xl;
  border-bottom: 1px solid $neutral-200;
}

.title-pill-wrapper {
  display: flex;
  gap: $spacing-md;
}

.head-row {
  padding: $spacing-lg $spacing-3xl;
  background-color: $neutral-50;
  border-top: 1px solid $neutral-200;
  border-bottom: 1px solid $neutral-200;
}
