@import 'design-system/styles';

.container {
  background-color: $white;
  border: size(1) solid $neutral-200;
  border-radius: size(12);
  display: flex;
  margin-top: size(xs);
  padding: size(12) size(sm);
  flex-wrap: wrap;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &-container {
    width: 100%;
  }
}

.divider {
  width: 100%;
  height: size(1);
  display: block;
  background-color: $neutral-200;
  margin: size(xs) size(z);
}
