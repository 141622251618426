@import 'design-system/styles';

.responsive-sidenav {
  border-right: size(1) solid $neutral-200;
  margin-left: size(-32); // break out of grid
  position: sticky;
  top: $spacing-7xl;
  overflow: auto;

  @include breakpoint-up('desktop') {
    margin-left: size(-44);
  }

  @include breakpoint-down('tablet') {
    overflow: hidden;
    margin-left: 0;
    margin-right: size(-32);
  }

  .content {
    height: calc(100vh - $spacing-7xl);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow: auto;

    @include breakpoint-down('tablet') {
      width: auto;
    }

    .menu {
      width: 100%;
      margin-top: $spacing-4xl;

      .retailer-logo {
        margin-left: $spacing-sm;
        max-width: calc(100% - $spacing-6xl);
        margin-bottom: $spacing-xl;
      }

      ul > a,
      ul > button {
        margin: 0;
        margin-right: $spacing-sm;
        border-radius: $spacing-sm;
      }

      li > a,
      li > button {
        margin-left: 0;
      }

      span {
        @include text-style('text-md-semibold');
      }

      svg {
        fill: $neutral-500;
      }
    }

    .lower {
      display: block;
      width: 100%;

      li > button {
        width: calc(100% - $sizing-sm);
      }

      .settings-accordion {
        span {
          @include text-style('text-md-semibold');
        }

        svg {
          fill: $neutral-500;
        }
      }

      a,
      button {
        text-decoration: none;
      }

      .cta {
        margin: 0 0 $spacing-lg 0;
      }

      .settings {
        margin-bottom: $spacing-xs;
      }
    }

    .request-to-pay {
      margin-bottom: $spacing-md;
    }

    .first-level-link {
      display: flex;
      align-items: center;
      gap: $spacing-lg;
      padding: $spacing-xs $spacing-lg;
      transition: background-color 0.2s ease-in-out;
      color: $neutral-700;
    }

    .lower-mid {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $spacing-xl;
      margin: $spacing-2xl $spacing-xl $spacing-3xl 0;
      padding: $spacing-2xl $spacing-xl;
      border-radius: $spacing-md;
      border: size(1) solid $neutral-200;
      color: $neutral-600;
    }

    .lower-bottom {
      border-top: size(1) solid $neutral-200;
      margin-right: $spacing-xl;
      padding: $spacing-3xl $spacing-xl;
      padding-right: 0;
      display: flex;
      align-items: center;
      gap: $spacing-lg;

      .lower-bottom-text {
        display: flex;
        flex-direction: column;
        gap: $spacing-md;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .email {
          color: $neutral-600;
        }
      }
    }
  }
}
