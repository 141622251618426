@import 'design-system/styles';

.dot {
  width: $spacing-sm;
  height: $spacing-sm;
  border-radius: 50%;
  display: inline-block;
  margin-right: $spacing-xs;
  border: $spacing-xs solid;
}
