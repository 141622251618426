@import 'design-system/styles';

.policy-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-3xl;
  align-self: stretch;
  padding: $spacing-3xl;
  border-radius: $spacing-xl;
  border: 1px solid $neutral-200;
  background: $white;
  box-shadow: $shadow-sm;
  margin-bottom: $spacing-4xl;

  // custom breakpoint for card header between tablet and desktop
  @media screen and (max-width: 950px) {
    flex-direction: column;
  }

  .policy-icon {
    width: $sizing-7xl;
    height: $sizing-7xl;
    border-radius: 50%;
    border: solid 1px $neutral-200;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .policy-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: $spacing-xl;

    .title {
      display: flex;
      gap: $spacing-md;
      align-items: center;
    }
  }
}

.header {
  margin-bottom: $spacing-xl;
  line-height: 1;
}

.subheader {
  margin-bottom: $spacing-2xl;
}

.section-header {
  margin-bottom: $spacing-xl;
}

.table {
  @include full-width-table(5);

  margin-bottom: $spacing-3xl;

  tr {
    &:hover {
      td {
        background-color: $neutral-50;
      }
    }
  }

  // first 2 columns
  td:nth-of-type(1),
  td:nth-of-type(2) {
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  tr.active td {
    background-color: $purple-50;
  }
}

.cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;

  .cta {
    display: flex;
    align-items: center;

    .icon-container {
      padding-left: $spacing-md;
      display: flex;

      @include override-icon-color($purple-700);
    }
  }
}

.filename-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: $spacing-lg;
  white-space: normal;

  > svg {
    flex-shrink: 0;
  }
}

.download-button:hover,
.download-button:focus {
  color: $purple-700;
}
