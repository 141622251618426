@import 'design-system/styles';

.paywall-row {
  display: flex;
  justify-content: space-between;
  padding: $spacing-xl $spacing-3xl;
  box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
  border: 1px solid $neutral-200;
  border-radius: $spacing-md;
  align-items: center;

  &[data-active='true'] {
    box-shadow: 0 1px 2px $spacing-xs #9e77ed3d;
  }
}

.sku-count {
  display: flex;
  gap: $spacing-md;
  align-items: baseline;
}

.pill-container {
  display: flex;
  gap: $spacing-3xl;
  align-items: center;
}

.price-container {
  display: flex;
  gap: $spacing-xs;
  align-items: baseline;
}

.paywall-content {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.action-row {
  display: flex;
  gap: $spacing-lg;
  padding-top: $spacing-4xl;
  padding-bottom: $spacing-xl;

  button {
    flex: 1;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
}

.fine-print {
  font-style: italic;
  width: 100%;
  text-align: center;
}
