@import 'design-system/styles';

.root {
  padding: size(md);
  border: size(1) solid $neutral-200;
  border-radius: size(sm);
  background-color: $white;

  p {
    margin: size(z);
  }

  & > button {
    width: 100%;
  }

  button {
    display: flex;
    justify-content: center;

    svg > path {
      fill: $blue-700;
      stroke: $blue-700;
    }
  }

  .stats {
    ul {
      list-style: none;
    }
  }

  .stats:not(:last-of-type) {
    margin-bottom: size(xs);
  }

  .label {
    display: flex;
    align-items: center;
    gap: size(xxs);
    margin-bottom: size(xxs);

    svg {
      margin-bottom: size(-1);
    }
  }

  .value {
    margin-bottom: size(xs);
  }
}

.loading-bar,
%loading-bar {
  margin-bottom: size(xs);
  border-radius: size(xxs);
  display: block;
  background: $neutral-200;

  &-small {
    @extend %loading-bar;

    width: 45%;
    height: size(sm);
  }

  &-large {
    @extend %loading-bar;

    width: 75%;
    height: size(20);
    margin-bottom: size(sm);
  }
}

.shimmer-background {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    $neutral-100 8%,
    $neutral-200 18%,
    $neutral-100
  );
  background-size: size(900) 100%;
}

@keyframes shimmer {
  0% {
    background-position: size(-900) 0;
  }

  100% {
    background-position: size(900) 0;
  }
}
