@import './functions';
@import './variables';
@import './mixins';

.sr-only {
  position: absolute;
  width: size(1);
  height: size(1);
  padding: size(z);
  margin: size(-1);
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: size(z);
}

.page-layout,
%page-layout {
  padding-bottom: size(xxl);
  max-width: size(1500);
}

.no-sidebar-layout {
  @extend %page-layout;

  display: grid;
  grid-template-columns: size(264) 1fr;
  margin: size(xxl) size(200) size(xxl) size(md);
  gap: size(md);
  padding: 0;
  align-items: flex-start;
}

.textarea {
  @include text-style('text-md');
  @include focus-state;

  padding: size(sm);
  border-radius: size(12);
  border: size(1) solid $neutral-200;
  background-color: $white;
  min-height: size(125);
  resize: none;
}
