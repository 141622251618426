@import 'design-system/styles';

.hamburger-button {
  display: inline-block;
  cursor: pointer;
  background: transparent;
  border: none;
}

.line {
  width: 26px;
  height: 2px;
  background-color: $neutral-100;
  margin: 6px auto;
  transition: all 0.3s ease;
}

/* Open state */
.open:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.open:nth-child(2) {
  opacity: 0;
}

.open:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}
