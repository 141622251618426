@import 'design-system/styles';

.root {
  background-color: $white;
  padding: size(xs) size(lg);
  list-style: none;
  cursor: pointer;

  &:hover {
    background-color: $neutral-200;
  }
}

.checkbox {
  @include text-style('text-sm');

  display: flex;
  margin: size(sm) size(z);
  align-items: center;
  gap: size(xs);
}
