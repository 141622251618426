@import 'design-system/styles';

.container {
  label {
    @include text-style('text-sm-bold');
  }
}

.input-container,
%input-container {
  border: size(1) solid $neutral-200;
  border-radius: size(12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: size(xs);
  padding: size(12) size(sm);
  background-color: $white;
  width: 100%;
}

.input {
  @include text-style('text-md');
  @include focus-state;

  min-width: size(24);
  width: 100%;
  border: none;
  color: $neutral-800;
  padding: size(2);
}

.input-container-error,
.error {
  @extend %input-container;

  border-color: $orange-500;
}

.error {
  justify-content: flex-start;
  padding: size(21) size(sm);
  background-color: $orange-100;

  & > *:first-child {
    margin-right: size(xs);
  }
}

.disabled {
  background-color: $neutral-100;

  input {
    background-color: $neutral-100;
    color: $neutral-400;
  }
}

.adornment-wrapper {
  $border-radius-value: size(12);

  display: flex;

  & > div {
    border-radius: 0;

    &:first-of-type {
      border-top-left-radius: $border-radius-value;
      border-bottom-left-radius: $border-radius-value;
    }

    &:last-of-type {
      border-top-right-radius: $border-radius-value;
      border-bottom-right-radius: $border-radius-value;
    }
  }
}

.adornment {
  @include text-style('text-md');

  background-color: $neutral-200;
  margin-top: size(xs);
  padding: size(12) size(sm);
  display: flex;
  align-items: center;
}

.required {
  margin-left: size(xxs);
}
