@import 'design-system/styles';

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.disabled {
  background-color: $neutral-100;

  input,
  .input {
    background-color: $neutral-100;
    color: $neutral-400;
  }

  svg > path,
  svg > circle {
    stroke: $neutral-400;
  }
}

.input,
.results {
  background-color: $white;
  position: relative;
}

.input {
  display: flex;
  padding: size(12) size(sm);
  border-radius: size(xs);
  gap: size(xs);
  border: size(1) solid $neutral-200;
  width: 100%;
  align-items: center;

  input {
    @include text-style('text-md');

    width: 100%;
    border: none;

    &::placeholder {
      color: $neutral-500;
    }
  }
}

.results {
  padding: size(z);
  margin: size(z);
  border: size(1) solid $neutral-200;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  top: 100%;
  margin-top: size(xs);
  border-radius: size(xs);
  box-shadow: 0 size(xxs) size(xs) rgb(0 0 0 / 5%);
  z-index: 1;
  max-height: size(240);
}
