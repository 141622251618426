@import 'design-system/styles';

.breadcrumbs {
  margin-bottom: size(xs);
}

.banner {
  width: 100%;
  border-radius: size(12);
  margin-top: size(sm);
  margin-bottom: size(sm);
  text-align: center;

  p {
    padding: size(md);
  }

  &-orange {
    background-color: $orange-100;
    color: $orange-800;
  }

  &-purple {
    background-color: $purple-100;
    color: $purple-800;
  }

  &-blue {
    background-color: $blue-100;
    color: $blue-800;
  }

  &-red {
    background-color: $red-100;
    color: $red-800;
  }

  &-green {
    background-color: $green-100;
    color: $green-800;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: size(20);

  .title-block {
    display: flex;
    align-items: center;
    gap: size(xs);
  }

  .sub-title {
    margin-top: size(sm);
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: size(sm);
  }
}
