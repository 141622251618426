@import 'design-system/styles';

.loading {
  display: flex;
  align-items: center;

  svg {
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
