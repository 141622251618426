@import 'design-system/styles';

.root {
  display: flex;
  flex-direction: column;
  gap: size(sm);
}

.cta-text {
  margin-bottom: size(sm);
}

.button {
  &-blue {
    background-color: transparent;
    box-shadow: inset 0 0 0 size(1) $white;
    color: $white;

    &:hover {
      background-color: rgba($black, 10%);
    }
  }

  &-white {
    background-color: transparent;

    &:hover {
      background-color: rgba($black, 10%);
    }
  }
}
