@import 'design-system/styles';

.trigger-container {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  padding: $spacing-md;
}

.options-row {
  display: flex;
  align-items: center;
  gap: $spacing-5xl;
  margin-bottom: $spacing-3xl;
}

.filters-wrapper {
  display: flex;
  gap: $spacing-3xl;
}

.nowrap {
  text-wrap: nowrap;
}

.action-button-container {
  .action-buttons {
    display: flex;
    gap: $spacing-lg;
    text-align: right;
  }
}
