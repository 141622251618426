@import 'design-system/styles';

.container {
  & > div:not(:last-of-type) {
    margin-bottom: size(xs);
  }
}

.title,
.subtitle {
  margin-bottom: size(sm);
}

.placeholder {
  background-color: $neutral-200;
  padding: size(md) size(z);
  border-radius: size(10);
  display: flex;
  justify-content: center;
}
