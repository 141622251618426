@import 'design-system/styles';

.root {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xl;
}

.form-row {
  display: flex;
  align-items: flex-start;
  gap: $spacing-3xl;

  label {
    cursor: pointer;
  }
}

.form-col {
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;

  label {
    cursor: pointer;
  }
}

.label-column {
  flex: 0 1 30%;
}

.subtitle {
  font-size: 0.8em;
  color: #666;
}

.input-column {
  flex: 1 1 70%;
  display: flex;
  gap: $spacing-lg;
  max-width: 512px;
}

.input-wrapper {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 #1018280d;
  border: 1px solid #d0d5dd;
  padding: 10px 14px;
  display: flex;
  align-items: center;
  gap: 10px;

  &:focus-within {
    border: 1px solid #8d7aef;
    outline-color: #8d7aef;
    box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%), 0 0 0 4px rgb(158 119 237 / 24%);
  }

  &.phone-prefix {
    max-width: 74px;
  }
}

.input {
  width: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  font-family: Inter, sans-serif;
  color: #101828;

  &::placeholder {
    color: #667085;
  }
}

.pill-text-area {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 1px 2px 0 #1018280d;
  width: 100%;
  min-height: 154px;
  cursor: text;

  .pill-delete {
    cursor: pointer;
  }

  &:focus-within {
    border: 1px solid #8d7aef;
    outline-color: #8d7aef;
    box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%), 0 0 0 4px rgb(158 119 237 / 24%);
  }
}

.pills-input {
  transform: translateX(-100%);
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

.pills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 5px;
}

.pill {
  padding: 2px 4px 2px 9px;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 5px;

  &.selected {
    background-color: #0070ff;
    color: #fff;
  }
}

.section-header {
  margin-top: $spacing-8xl;

  &.no-margin {
    margin-top: 0;
  }
}
