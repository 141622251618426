@import 'design-system/styles';

@keyframes hide-toast {
  0% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes hide-bar {
  0% {
    width: 100%;
  }

  5% {
    border-bottom-right-radius: size(z);
  }

  100% {
    width: size(z);
    border-bottom-right-radius: size(z);
  }
}

.container,
%container {
  border-radius: size(12);
  box-shadow: 0 size(2) size(4) rgb(0 0 0 / 5%);
  top: size(84);
  opacity: 1;
  position: fixed;
  right: size(md);
  width: size(290);
  z-index: $z-index-toast;
  background-color: $white;

  &-success {
    @extend %container;

    .bar {
      background-color: $green-500;
    }
  }

  &-error {
    @extend %container;

    .bar {
      background-color: $red-500;
    }
  }
}

.auto-dismiss {
  @extend %container;

  animation: hide-toast 5s linear;
  opacity: 0;
}

.hide {
  display: none;
}

.content {
  padding: size(md) size(md) size(sm) size(md);
}

.header,
.title {
  display: flex;
}

.header {
  height: size(lg);
  justify-content: space-between;
  margin-bottom: size(xs);
}

.title {
  align-items: center;

  & > *:first-child {
    margin-right: size(xs);
  }
}

.bar {
  width: size(z);
  height: size(10);
  animation: hide-bar 5s linear;
  border-bottom-left-radius: size(12);
  border-bottom-right-radius: size(12);
}
