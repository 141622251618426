@import 'design-system/styles';

$border-radius: size(xs);

// default variant
.container {
  border: solid size(1) $neutral-200;
  border-radius: $border-radius;
  width: fit-content;
}

.button,
%button {
  @include text-style('text-sm-bold');

  padding: size(15) size(12);
  border: solid size(1) $white;
  background-color: $white;
  color: $black;

  &:hover {
    background-color: $neutral-100;
    border-color: $neutral-100;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &-active,
  &-active:hover {
    @extend %button;

    background-color: $purple-100;
    border-color: $purple-100;
    color: $purple-500;
    cursor: default;
  }
}

// outlined variant
.container-outlined {
  border: solid size(1) $neutral-300;
  border-radius: $border-radius;
  width: fit-content;
  box-shadow: $shadow-xs;
}

.button-outlined,
%button-outlined {
  @include text-style('text-xs-bold');

  // display
  display: inline-flex;
  gap: $spacing-md;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  // dimensions
  padding: $spacing-md $spacing-xl;
  border: none;
  border-right: solid size(1) $neutral-300;

  // colors
  background-color: $white;
  color: $neutral-700;
  cursor: pointer;

  &:hover {
    background-color: $neutral-50;
    color: $black;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-right: none;
  }

  &-active,
  &-active:hover {
    @extend %button-outlined;

    color: $black;
    background-color: $neutral-50;
    cursor: default;
  }
}
