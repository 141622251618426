@import 'design-system/styles';

.table {
  @include full-width-table(3);

  tr {
    &:hover {
      td {
        background-color: $neutral-50;
      }
    }
  }

  tr.active td {
    background-color: $purple-50;
  }
}

.cta {
  display: flex;

  .icon-container {
    padding-left: $spacing-md;
    display: flex;

    @include override-icon-color($purple-700);
  }
}
