@import 'design-system/styles';

.root {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xl;

  .phone-prefix {
    max-width: 74px;
  }
}

.checkbox-container {
  display: flex;
  margin: $spacing-md $spacing-none;
  gap: $spacing-sm;
  align-items: baseline;
}
