@import 'design-system/styles';

.editable-title {
  cursor: pointer;
}

.title {
  margin: 0;
  margin-right: 0.15rem;
  display: flex;
  align-items: center;

  h1 {
    margin: 0;
  }

  svg {
    margin-left: size(xxs);
  }
}

.input {
  @include text-style('display-sm-bold');

  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  color: $neutral-700;
  background: transparent;

  :focus {
    outline: none;
    border: none;
  }
}
