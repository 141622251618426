@import 'design-system/styles';

.caption {
  color: $neutral-600;
  display: block;
  margin-bottom: size(12);
  text-align: left;
}

.root,
%root {
  border-spacing: 0;
  display: block;
  overflow: auto;
  text-align: left;
  border: solid 1px $neutral-200;
  border-radius: $sizing-xl;

  thead th:nth-child(1),
  tbody tr td:nth-child(1) {
    padding-left: size(sm);
  }

  thead th:last-child,
  tbody tr td:last-child {
    padding-right: size(sm);
  }

  caption,
  thead th:nth-child(1),
  tbody tr td:nth-child(1) {
    left: 0;
    position: sticky;
  }

  tr > * {
    border-bottom: solid size(1) $neutral-200;
  }

  th,
  td {
    background-color: $white;
    color: $neutral-600;
  }

  th {
    padding: size(sm) size(xs);
    background-color: $neutral-50;
  }

  td {
    padding: size(xs) size(xs);
  }

  &-scrolled {
    @extend %root;

    thead th:nth-child(1),
    tbody tr td:nth-child(1) {
      box-shadow: size(xxxs) size(z) size(xxs) rgb(0 0 0 / 5%),
        -16px size(z) 20px 3px $neutral-100;
    }
  }
}

.storybook {
  @include full-width-table(2);
}
