@import 'design-system/styles';

.root,
%root {
  padding: $spacing-xl $spacing-2xl;
  padding-left: $spacing-lg;
  cursor: pointer;
  transition: all 0.15s;
  text-decoration: none;
  display: flex;
  margin: $spacing-sm;
  align-items: center;
  gap: size(12);
  background-color: $white;
  color: $neutral-500;
  border: none;

  &:hover {
    span {
      color: $neutral-800;
    }

    background-color: $neutral-50;
    border-radius: $spacing-sm;

    @include override-icon-color($neutral-500);
  }

  &-active {
    @extend %root;

    span {
      color: $neutral-800;
    }

    border-radius: $spacing-sm;
    background-color: $neutral-100;

    @include override-icon-color($neutral-500);
  }

  &.compact {
    padding: $spacing-md $spacing-2xl;
    margin: $spacing-xs $spacing-sm;
  }
}
