@import 'design-system/styles';

.container {
  padding-top: size(5);
  flex-grow: 1;

  & > * {
    margin-bottom: size(sm);
  }
}

.title {
  margin: size(xs) size(z) size(sm) size(z);
}

.policies-link {
  display: flex;
  align-items: center;
  padding: size(sm) size(md);
  background-color: $neutral-100;
  border: solid 1px $neutral-200;
  border-radius: size(sm);

  a {
    margin-left: auto;
  }
}

.split {
  display: flex;
  flex-direction: column;
  gap: size(sm);
}
