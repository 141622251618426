@import 'design-system/styles';

$border-radius-val: 10;

.container {
  flex-grow: 1;

  & > :not(div:first-of-type) {
    border-radius: 0;
  }

  & > div:last-of-type {
    border-bottom-right-radius: size($border-radius-val);
    border-bottom-left-radius: size($border-radius-val);
  }
}
