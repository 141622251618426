@import 'design-system/styles';

.policy-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: $spacing-5xl;
  align-self: stretch;
  padding: $spacing-3xl;
  border-radius: $spacing-xl;
  border: 1px solid $neutral-200;
  background: $white;
  box-shadow: $shadow-sm;
  margin-bottom: $spacing-4xl;

  // custom breakpoint for card header between tablet and desktop
  @media screen and (max-width: 950px) {
    flex-direction: column;
  }

  .policy-icon {
    width: $sizing-7xl;
    height: $sizing-7xl;
    border-radius: 50%;
    border: solid 1px $neutral-200;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .policy-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 64px;

    &.centered {
      justify-content: center;
    }

    .policy-header {
      display: flex;
      align-items: center;
      gap: $spacing-lg;

      @include breakpoint-down('tablet') {
        flex-direction: column;
        gap: $spacing-md;
        align-items: start;
      }
    }

    .policy-pillars {
      display: flex;
      align-items: center;
      gap: $spacing-md;
      margin-top: $spacing-md;
    }
  }

  .policy-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;

    .cta {
      display: flex;
      align-items: center;

      .icon-container {
        padding-left: $spacing-md;
        display: flex;

        @include override-icon-color($purple-700);
      }
    }
  }
}
