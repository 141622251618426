@import 'design-system/styles';

.container,
.set,
.policy,
.file,
%alert {
  display: flex;
}

.container,
.set,
%alert {
  flex-direction: column;
}

.set,
%alert {
  gap: spacing(xs);
}

.policy,
.file {
  gap: spacing(sm);
}

.container {
  gap: spacing(xl);
}

%alert {
  @include border-radius(sm);

  background-color: $orange-100;
  border: size(1) solid $orange-300;
  padding: spacing(lg);
}

.info {
  @extend %alert;

  background-color: $blue-100;
  border-color: $blue-300;
}

.warning {
  @extend %alert;

  background-color: $orange-100;
}

.file {
  @include border-radius(sm);

  align-items: center;
  border: size(1) solid $neutral-200;
  padding: spacing(md);
}
