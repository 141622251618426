@import 'design-system/styles';

.carousel {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  gap: 10px;
  height: inherit;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 15px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  padding-bottom: 30px !important;
  overflow-x: hidden !important;
  width: 100%;
}

.carousel-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  height: inherit;
  position: relative;

  .single-col-btn-style:first-of-type {
    left: 10px;
  }

  .single-col-btn-style:last-of-type {
    right: 10px;
  }
}

.button {
  padding: 8px;
}

.image-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  scroll-snap-align: start;

  > img {
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.clickable-image {
  > img {
    cursor: pointer;
  }
}

.single-col-btn-style {
  background-color: white;
  opacity: 0.7;
  transition: 0.1s;
  position: absolute;
  z-index: 2;
  width: fit-content;
}

.bullets-wrapper {
  width: 100%;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  z-index: 2;
  gap: 5px;
}

.bullet {
  background-color: white;
  border-radius: 50%;
  border: 1px solid black;
  width: 10px;
  height: 10px;
  transition: 0.1s;
  cursor: pointer;
}

.active-bullet {
  background-color: black;
  cursor: default;
}
