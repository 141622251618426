@import 'design-system/styles';

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-item {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-md;
  cursor: pointer;
  background-color: transparent;
  border: none;
  gap: $spacing-md;
}

.radio-indicator {
  width: size(16);
  height: size(16);
  border-radius: 50%;
  border: size(1) solid $neutral-300;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-item[aria-checked='true'] .radio-indicator {
  background-color: $purple-600;
  border: size(1) solid $purple-600;

  &::after {
    width: size(6);
    height: size(6);
    content: '';
    border-radius: 50%;
    background-color: $white;
  }
}

.radio-item[aria-checked='false'] .radio-indicator {
  background-color: $white;
  display: block;
}

.error-container {
  margin-top: $spacing-md;
  padding: $spacing-xl;
  background-color: $red-100;
  border: size(1) solid $red-600;
  border-radius: $spacing-md;

  @include text-style(text-sm);
}

.pointer {
  cursor: pointer;
}
