@import 'design-system/styles';

.empty-section {
  display: flex;
  align-items: center;
  gap: $spacing-xl;
  flex-direction: column;
  margin-bottom: $spacing-3xl;
}

.empty-state-text {
  max-width: 416px;
  text-align: center;
}
