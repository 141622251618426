@import 'design-system/styles';

.display-2xl {
  @include text-style('display-2xl');
}

.display-2xl-medium {
  @include text-style('display-2xl-medium');
}

.display-2xl-bold {
  @include text-style('display-2xl-bold');
}

.display-xl {
  @include text-style('display-xl');
}

.display-xl-medium {
  @include text-style('display-xl-medium');
}

.display-xl-bold {
  @include text-style('display-xl-bold');
}

.display-lg {
  @include text-style('display-lg');
}

.display-lg-medium {
  @include text-style('display-lg-medium');
}

.display-lg-bold {
  @include text-style('display-lg-bold');
}

.display-md {
  @include text-style('display-md');
}

.display-md-medium {
  @include text-style('display-md-medium');
}

.display-md-bold {
  @include text-style('display-md-bold');
}

.display-sm {
  @include text-style('display-sm');
}

.display-sm-medium {
  @include text-style('display-sm-medium');
}

.display-sm-bold {
  @include text-style('display-sm-bold');
}

.display-xs {
  @include text-style('display-xs');
}

.display-xs-medium {
  @include text-style('display-xs-medium');
}

.display-xs-bold {
  @include text-style('display-xs-bold');
}

.display-2xs {
  @include text-style('display-2xs');
}

.display-2xs-medium {
  @include text-style('display-2xs-medium');
}

.display-2xs-bold {
  @include text-style('display-2xs-bold');
}

.text-xl {
  @include text-style('text-xl');
}

.text-xl-medium {
  @include text-style('text-xl-medium');
}

.text-xl-semibold {
  @include text-style('text-xl-semibold');
}

.text-xl-bold {
  @include text-style('text-xl-bold');
}

.text-lg {
  @include text-style('text-lg');
}

.text-lg-medium {
  @include text-style('text-lg-medium');
}

.text-lg-semibold {
  @include text-style('text-lg-semibold');
}

.text-lg-bold {
  @include text-style('text-lg-bold');
}

.text-md {
  @include text-style('text-md');
}

.text-md-medium {
  @include text-style('text-md-medium');
}

.text-md-semibold {
  @include text-style('text-md-semibold');
}

.text-md-bold {
  @include text-style('text-md-bold');
}

.text-sm {
  @include text-style('text-sm');
}

.text-sm-medium {
  @include text-style('text-sm-medium');
}

.text-sm-semibold {
  @include text-style('text-sm-semibold');
}

.text-sm-bold {
  @include text-style('text-sm-bold');
}

.text-xs {
  @include text-style('text-xs');
}

.text-xs-medium {
  @include text-style('text-xs-medium');
}

.text-xs-semibold {
  @include text-style('text-xs-semibold');
}

.text-xs-bold {
  @include text-style('text-xs-bold');
}
