@import 'design-system/styles';

$base-color: $neutral-700;
$initial-color: rgba($base-color, 0.1);
$alternate-color: rgba($base-color, 0.2);
$box-shadow-size: 0 0 1px 2px;

@keyframes pulsate {
  0% {
    background-color: $initial-color;
    box-shadow: $box-shadow-size $initial-color;
  }

  50% {
    background-color: $alternate-color;
    box-shadow: $box-shadow-size $alternate-color;
  }

  100% {
    background-color: $initial-color;
    box-shadow: $box-shadow-size $initial-color;
  }
}

.skeleton {
  display: block;
  border-radius: size(4);
  animation: 2s infinite pulsate;
}
