@import 'design-system/styles';

.policy {
  margin-bottom: $spacing-3xl;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-4xl;
}
