@import 'design-system/styles';

.input-label-group {
  width: 100%;

  p {
    margin: size(z) size(z) size(xs);
  }

  .input {
    border-radius: size(12);
    border: size(1) solid $neutral-200;
    padding: size(12) size(sm);
    width: 100%;
    background: transparent;

    @include text-style('text-md');

    &-error {
      border-color: $red-500;
    }
  }

  .error {
    color: $red-500;
    margin-top: size(xxs);
    font-size: size(14);
    line-height: size(sm);
  }

  &--with-icon {
    display: flex;
    align-items: center;
    gap: size(xs);
  }
}

.input-container {
  position: relative;

  .right-section {
    position: absolute;
    right: size(z);
    bottom: size(z);
    top: size(z);
    min-width: size(40);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;

    @include text-style('text-md');

    background-color: $neutral-100;
    color: $neutral-500;
    border-radius: size(z) size(12) size(12) size(z);
  }

  input[type='number'] {
    appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
  }
}

.modal-content {
  display: grid;
  gap: size(sm);
  grid-template-columns: 1fr 1fr;
  max-height: size(400);
  overflow-y: auto;
}

.modal-description {
  background: $orange-100;
  border: size(1) solid $orange-500;
  margin: 0 0 size(md);
  border-radius: size(12);
  padding: size(sm);

  .description-header {
    margin-bottom: size(xs);
  }
}
