@import 'design-system/styles';

.root {
  font-weight: 600;
  color: $purple-700;
  font-size: 74px;
  letter-spacing: -2px;
  line-height: 0.75;
  font-family: Circular, sans-serif;
}

.lg {
  font-size: 74px;
}

.sm {
  font-size: 35px;
}
