@import 'design-system/styles';

.document-section {
  display: flex;
  align-items: center;
  gap: size(xs);
  margin: size(xs) 0 size(sm);
}

.notes-container {
  padding: size(12) size(sm);
  border: size(1) solid $neutral-200;
  border-radius: size(12);
  margin-top: size(xs);
}
