@import 'design-system/styles';

.table {
  @include full-width-table(4);
}

.table-with-border {
  border: 1px solid $neutral-200;
  border-radius: $spacing-lg;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: $spacing-9xl;
}

.tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 20px;
}

.actions {
  display: flex;
  gap: 10px;
}

.actions-bottom-row {
  display: flex;
  justify-content: flex-end;
}
