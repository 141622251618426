@import 'design-system/styles';

.label {
  @include text-style('text-sm-medium');

  position: relative;
  display: flex;
  flex-direction: column;
  color: $neutral-700;
  margin-bottom: $spacing-sm;

  .required {
    color: $red-600;
  }
}

.multi-select {
  appearance: none; // Removes native styling
  display: flex;
  margin-top: $spacing-md;
  align-items: center;
  gap: $spacing-8xl;
  align-self: stretch;
  border-radius: $spacing-md;
  border: 1px solid $neutral-300;
  background: $white;
  box-shadow: $shadow-xs;

  &:focus {
    border-radius: $spacing-md;
    border: 1px solid $purple-300;
    background: $white;
    outline-color: $purple-300;

    @include focus-state;
  }
}

.selected {
  display: flex;
  align-items: flex-end;
  gap: $spacing-xs;
  flex-wrap: wrap;

  span {
    display: flex;
    align-items: center;
  }
}

.icon {
  position: absolute;
  right: 14px;
}

.pill {
  @include focus-state;
  @include border-radius('sm');
  @include text-style('text-xs-medium');

  color: $neutral-700;
  padding: 0.13rem 0.38rem 0.13rem 0.19rem;
  border: 1px solid $gray-300;
  display: flex;
  align-items: center;
  gap: 0.12rem;
  box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
  white-space: nowrap;

  .pill-delete {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $neutral-500;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  gap: $spacing-4xl;
  align-items: center;
  width: 100%;

  .option-text {
    flex-grow: 1;
  }
}
