@import 'design-system/styles';

.container {
  height: 85vh;

  @include breakpoint-down('tablet') {
    height: 61vh;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
