@import 'design-system/styles';

.container {
  ul {
    max-height: size(400);
    overflow-y: auto;
    overflow-x: hidden;

    li {
      width: initial;
    }
  }
}

.title {
  margin: size(sm) size(z) size(xs) size(z);
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: size(1) solid $neutral-200;
  border-radius: size(10);
  padding: size(sm);
}
