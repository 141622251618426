@import 'design-system/styles';

$border-radius: size(12);

.container {
  position: relative;

  &-wide {
    position: relative;

    .trigger,
    .trigger-active,
    .menu {
      width: 100%;
    }
  }
}

.trigger,
%trigger {
  @include focus-state;

  background-color: transparent;
  padding: size(z);
  border: none;
  border-radius: size(xs);
  cursor: pointer;

  &:hover {
    background-color: $neutral-200;
  }

  &-active {
    @extend %trigger;

    background-color: $neutral-200;
  }
}

.menu,
%menu {
  position: absolute;
  z-index: $z-index-tooltip;
  padding: size(z);
  margin: size(z);
  margin-top: size(xs);
  box-shadow: 0 size(2) size(4) rgb(0 0 0 / 5%);
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  white-space: nowrap;
  overflow-y: auto;

  &-left {
    @extend %menu;

    right: size(z);
  }

  &-top {
    @extend %menu;
  }

  & > li:first-of-type {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  & > li:last-of-type {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  & > li:not(:last-of-type) {
    border-bottom: none;
  }
}

.fixed {
  position: fixed;
}
