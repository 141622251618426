@import 'design-system/styles';

.root {
  position: relative;
  margin: auto;
  width: 100%;
  margin-bottom: $spacing-sm;
}

.small {
  input {
    padding: $spacing-md;
  }
}

.medium {
  input {
    padding: 0.625rem 0.875rem;
  }
}

.lead-wrapper {
  position: absolute;
  left: $spacing-md;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.lead-icon {
  display: flex;
  align-items: center;
}

.lead-children-wrapper {
  display: flex;
  gap: $spacing-xs;
  padding-left: $spacing-md;
  align-items: center;
}

.end-icon {
  position: absolute;
  right: $spacing-md;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.input {
  width: 100%;
  border-radius: $spacing-md;
  border: 1px solid $slate-100;
  font-size: $spacing-xl;
  outline: none;
  transition: 0.2s;
  box-sizing: border-box;
}

.input.error {
  border: 1px solid $red-300;
  overflow: hidden;
}

.input:focus {
  border-color: $purple-200;
  box-shadow: 0 0 0 4px rgb(158 119 237 / 24%), 0 1px 2px 0 rgb(16 24 40 / 5%);
}

.input:focus.error {
  border: solid $red-300;
  box-shadow: 0 0 0 4px rgb(240 68 56 / 24%), 0 1px 2px 0 rgb(16 24 40 / 5%);
}

.error-icon {
  transform: translateY(-50%);
}
