@import 'design-system/styles';

.banner {
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(24 13 91 / 30%);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100vw;
  min-height: 100vh;
  margin: 0;

  .logo-container {
    padding: $spacing-xl $spacing-3xl;
  }
}

.xl {
  margin-bottom: $spacing-xl;
}

.content {
  background: white;
  padding: $spacing-4xl 0;
  box-shadow: 0 20px 24px -4px rgb(16 24 40 / 8%);
  width: size(1080);
  max-width: calc(100vw - $spacing-11xl);
  text-align: center;
  margin: $spacing-9xl auto;

  button {
    width: 100%;
    justify-content: center;
  }

  h1 {
    margin: $spacing-6xl 0 $spacing-3xl;
  }

  h2 {
    text-align: left;
    margin-bottom: $spacing-3xl;
  }

  .sub-title {
    text-align: center;
  }

  @include border-radius(xl);
}

.pillars-container {
  h2 {
    margin-bottom: $spacing-4xl;
  }
}

.card {
  padding: $spacing-4xl;
  border-radius: $spacing-2xl;
  border: size(1) solid $neutral-200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-4xl;
  margin-bottom: $spacing-3xl;

  @include breakpoint-down('tablet') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.title-group {
  display: flex;
  align-items: center;
  gap: $spacing-md;
  margin-bottom: $spacing-2xl;

  @include breakpoint-down('desktop') {
    gap: $spacing-xl;
  }
}

.card-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: $spacing-4xl;
  width: 100%;
  text-align: left;

  @include breakpoint-down('tablet') {
    flex-direction: column;
    align-items: flex-end;
  }

  p {
    line-height: $spacing-2xl;
  }
}

.pricing {
  border-radius: $spacing-2xl;
  border: size(1) solid $neutral-200;
  height: 100%;
  padding: $spacing-4xl;
  display: flex;
  flex-direction: column;
  gap: $spacing-3xl;
  text-align: left;

  .title-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-xl;
  }

  .pricing-rows {
    display: flex;
    flex-direction: column;
    gap: $spacing-xl;
    padding: 0 $spacing-md;

    .pricing-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.contact-text {
  border-bottom: 1px solid $purple-700;
  color: $purple-700;
}

.resources {
  display: flex;
  gap: size(xl);
  padding: size(xl);
  flex: 1;
  align-items: center;
}

.panel {
  padding: size(xl);
  border-radius: size(sm);
  border: size(1) solid $neutral-200;
  box-shadow: $shadow-xs;
  text-align: left;
}

.full-height {
  height: 100%;
}

.subtext {
  padding-bottom: $spacing-5xl;

  a {
    color: $purple-700;
  }
}

.fallback {
  background-color: $neutral-100;
  border-radius: size(sm);
}

.continue-container {
  margin-top: $spacing-3xl;
}
