@import 'design-system/styles';

.input {
  outline: none;
  border: none;
  font-size: $sizing-xl;
  font-weight: 300;
  line-height: $sizing-3xl;
  font-family: Inter, sans-serif;
  color: #101828;

  &::placeholder {
    color: #667085;
  }
}

.pill-text-area {
  @include focus-state;
  @include border-radius('md');

  border: 1px solid $gray-300;
  padding: $spacing-lg;
  box-shadow: $shadow-xs;
  width: 100%;
  min-height: 154px;
  cursor: text;

  .pill-delete {
    cursor: pointer;
  }
}

.pills-input {
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
}

.pills-container {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 5px;

  // ul default overrides
  list-style-type: none;
  margin-block: 0;
  margin-inline: 0;
  padding-inline: 0;
}

.pill {
  @include focus-state;
  @include border-radius('sm');

  padding: 2px 4px 2px 9px;
  border: 1px solid $gray-300;
  display: flex;
  align-items: center;
  gap: 5px;

  &.selected {
    background-color: #0070ff;
    color: #fff;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
