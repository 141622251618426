@import 'design-system/styles';

.ring-root,
%root {
  .indicator,
  %indicator {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: all 0.3s;
    stroke-linecap: round;
    fill: transparent;
  }

  .loading {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    animation: rotate-animation ease-in-out infinite;
    animation-duration: 1.2s;
    stroke-linecap: round;
    fill: transparent;
  }

  .circle,
  %circle {
    stroke: $neutral-200;
    fill: transparent;
  }

  text {
    text-anchor: middle;
  }

  &-sm {
    @include text-style('text-sm-semibold');

    width: size(64);
    height: size(64);
    stroke-width: $spacing-sm;
    @extend %root;
    @extend %circle;
    @extend %indicator;

    .label-text {
      @include text-style('text-xs-medium');

      fill: $neutral-500;
      margin-top: $spacing-md;
    }
  }

  &-lg {
    @include text-style('display-xs-medium');

    width: size(144);
    height: size(144);
    stroke-width: $spacing-xl;
    @extend %root;
    @extend %circle;
    @extend %indicator;

    .label-text {
      @include text-style('text-xs-medium');

      fill: $neutral-500;
    }
  }
}

.ring-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;

  p {
    margin-top: $spacing-xxs;
    color: $neutral-500;
  }
}

.bar-container {
  text-align: right;

  .bar-root {
    position: relative;
    height: $spacing-md;
    border-radius: $spacing-xs;
    background: $neutral-200;

    .bar-rail {
      position: absolute;
      height: $spacing-md;
      border-radius: $spacing-xs;
      left: 0;
    }
  }

  .label {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: $neutral-700;
    margin-top: $spacing-md;
  }
}

@keyframes rotate-animation {
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(270deg);
  }
}
