@import 'design-system/styles';

.root,
%root {
  transform-origin: center;
  transition: all 0.25s;

  &-up {
    @extend %root;
  }

  &-right {
    @extend %root;

    transform: rotate(90deg);
  }

  &-down {
    @extend %root;

    transform: rotate(180deg);
  }

  &-left {
    @extend %root;

    transform: rotate(270deg);
  }
}
