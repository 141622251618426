@import 'design-system/styles';

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: size(xs);

  @include breakpoint-down('tablet') {
    margin: 0 20vw;
    flex-direction: column;
  }
}

.close {
  position: absolute;
  right: size(sm);
}

.close:hover {
  cursor: pointer;
}

.link {
  display: inline;
}
