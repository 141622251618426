@import 'design-system/styles';

.container {
  display: flex;
  align-items: center;

  .value {
    margin-left: size(xxxs);
  }
}
