@import 'design-system/styles';

.card {
  padding: $spacing-3xl;
  border-radius: $spacing-2xl;
  border: size(1) solid $neutral-200;
  margin-bottom: $spacing-3xl;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-4xl;
    margin-bottom: $spacing-2xl;

    .title {
      display: flex;
      align-items: center;
      gap: $spacing-md;
    }
  }

  .statuses {
    display: flex;
    align-items: center;
    gap: $spacing-xl;
    list-style-type: none;
  }

  .status-item {
    display: flex;
    align-items: center;
    gap: $spacing-md;
    @include text-style('text-sm-semibold');

    color: $neutral-500;
  }
}
